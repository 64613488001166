import ProfileComp from "../components/profile/profile"
import Panel from "../layout/Panel/Panel"

const Profile = () => {
  return (
    <div>
      <Panel>
        <ProfileComp />
      </Panel>
    </div>
  )
}

export default Profile
