import { createTheme, ThemeProvider } from "@mui/material/styles"
import { CacheProvider } from "@emotion/react"
import MUIDataTable from "mui-datatables"
import createCache from "@emotion/cache"
import Panel from "../layout/Panel/Panel"

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

const Earnings = () => {
  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none", // toggle checkbox
    onTableChange: (action, state) => {},
  }

  const columns = [
    {
      name: "id",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "planName",
      label: "Plan Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "planPrice",
      label: "Plan Price",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "payableAmount",
      label: "Payable Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Active
              data={tableMeta}
              index={6}
              text="received"
            />
          )
        },
      },
    },
  ]

  const data = [
    {
      id: 1,
      planName: "Basic",
      planPrice: "$100",
      payableAmount: "$100",
      startDate: "1-02-2023",
      endDate: "1-02-2024",
      status: "received",
    },
    {
      id: 2,
      planName: "Standard",
      planPrice: "$300",
      payableAmount: "$300",
      startDate: "1-02-2023",
      endDate: "1-02-2024",
      status: "received",
    },
  ]

  return (
    <div>
      <Panel>
        <div
          className="c_card"
          style={{ height: "100%", overflowY: "scroll" }}
        >
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                title="Earnings"
                data={data}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>
        </div>
      </Panel>
    </div>
  )
}

const Active = ({ data, index, text }) => {
  return (
    <span
      style={{
        display: "block",
        padding: "2px 6px",
        width: "fit-content",
        textTransform: "capitalize",
        fontWeight: 600,
        borderRadius: 6,
        fontSize: 13,
        background: data.rowData[index] === text ? "#2563eb33" : "#ff537433",
        color: data.rowData[index] === text ? "#2563EB" : "#ff5374",
      }}
    >
      {`${data.rowData[index]}`}
    </span>
  )
}

export default Earnings
