import axios from "axios";
import Cookies from "js-cookie";
import { errorHandler } from "../helper/errorhandler";
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../utils/constants";

axios.defaults.baseURL = "https://backend.wazcard.com/";
// axios.defaults.baseURL = "http://localhost:4000/";

const getJwt = () => {
  if (
    Cookies.get(JWT_STORAGE_NAME) !== undefined &&
    localStorage.getItem(JWT_STORAGE_NAME)
  ) {
    return Cookies.get(JWT_STORAGE_NAME);
    // return localStorage.getItem(JWT_STORAGE_NAME)
  } else {
    Cookies.remove(JWT_STORAGE_NAME);
    localStorage.removeItem(JWT_STORAGE_NAME);
    localStorage.removeItem(LOCAL_STORAGE_NAME);
  }
};

const setJwt = (token) => {
  Cookies.set(JWT_STORAGE_NAME, token, {
    expires: 2,
    path: "/",
    secure: false,
  });
  localStorage.setItem(JWT_STORAGE_NAME, token);
};

const setAuthHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  setJwt(token);
};

if (getJwt()) {
  setAuthHeader(getJwt());
}

export default class Api {
  //login user
  static userLogin = async (data) => {
    try {
      const res = await axios.post(`api/v1/user/login`, data);
      setAuthHeader(res.data.token);
      return res;
    } catch (error) {
      Cookies.remove(JWT_STORAGE_NAME);
      localStorage.removeItem(JWT_STORAGE_NAME);
      localStorage.removeItem(LOCAL_STORAGE_NAME);
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //change password
  static updatePassword = async (data) => {
    try {
      const res = await axios.put(`api/v1/user/password/change`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };
  //change password by admin
  static updatePasswordByAdmin = async (data) => {
    try {
      const res = await axios.put(`api/v1/admin/password/change`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update profile
  static updateUser = async (data) => {
    try {
      const res = await axios.put(`api/v1/user`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };
  //delete user
  static deleteUser = async (id) => {
    try {
      const res = await axios.delete(`api/v1/user/delete/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //getMyProfile
  static getMyProfile = async () => {
    try {
      const res = await axios.get(`api/v1/user/me`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //getAllUsers
  static getAllUsers = async () => {
    try {
      const res = await axios.get(`api/v1/user/all`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //getUsersCard
  static getUsersCard = async () => {
    try {
      const res = await axios.get(`api/v1/card/all/user`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //getAdminCards
  static getAdminCards = async () => {
    try {
      const res = await axios.get(`api/v1/card/all/admin`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //add card
  static getAllMyCards = async () => {
    try {
      const res = await axios.get(`api/v1/card/all/uid`);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //add card
  static createCard = async (data) => {
    try {
      const res = await axios.post(`api/v1/card`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  static deleteCard = async (id) => {
    try {
      const res = await axios.delete(`api/v1/card/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get card
  static getCard = async (title) => {
    try {
      const res = await axios.get(`api/v1/card/${title}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get card
  static getCardPublic = async (title) => {
    try {
      const res = await axios.get(`api/v1/card/public/${title}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update logo
  static updateLogo = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/logo`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update cover-photo
  static updateCoverPhoto = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/cover-photo`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update bg-photo
  static updateBgPhoto = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/bg-image`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update title
  static updateTitle = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/title`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update slogan
  static updateSlogan = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/slogan`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update number
  static updateNumber = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/number`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update text-color
  static updateTextColor = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/text-color`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update bg-color
  static updateBgColor = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/bg-color`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update cover-color
  static updateCoverColor = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/cover-color`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update hours-color
  static updateHoursColor = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/hours-color`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update review-color
  static updateReviewColor = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/review-color`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update floating-bg
  static updateFloatingBg = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/floating-bg`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update hours
  static updateHours = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/hours`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //update settings
  static updateSettings = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/settings`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //add review
  static addReview = async (data) => {
    try {
      const res = await axios.post(`api/v1/card/review`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //edit review
  static editReview = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/review`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //edit review status
  static editReviewStatus = async (id) => {
    try {
      const res = await axios.put(`api/v1/card/review/status/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //delete review
  static deleteReview = async (id) => {
    try {
      const res = await axios.delete(`api/v1/card/review/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get reviews
  static getAllReviews = async () => {
    try {
      const res = await axios.get(`api/v1/card/review/uid`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //add gallery
  static addGallery = async (data) => {
    try {
      const res = await axios.post(`api/v1/card/gallery`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //edit gallery
  static editGallery = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/gallery`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //delete gallery
  static deleteGallery = async (id) => {
    try {
      const res = await axios.delete(`api/v1/card/gallery/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //sort Gallery
  static sortGallery = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/gallery/sort`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update Card floatingIcon
  static updateCardFloatingIcon = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/floating-icon`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //add Icon
  static addIcon = async (data) => {
    try {
      const res = await axios.post(`api/v1/card/icon`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //sort Icon
  static sortIcon = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/icon/sort`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update Icon value
  static updateIconValue = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/icon-value`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //updateIcon
  static updateIcon = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/icon`, data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  //deleteIcon
  static deleteIcon = async (id) => {
    try {
      const res = await axios.delete(`api/v1/card/icon/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update card status
  static updateCardStatus = async (data) => {
    try {
      const res = await axios.put(`api/v1/admin/card/status`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update user status
  static updateUserStatus = async (data) => {
    try {
      const res = await axios.put(`api/v1/admin/user/status`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };
  //update role type
  static updateRole = async (data) => {
    try {
      const res = await axios.put(`api/v1/admin/role`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //add main icon
  static addMainIcon = async (data) => {
    try {
      const res = await axios.post(`api/v1/main-icon`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get all main icons
  static getMainIcons = async () => {
    try {
      const res = await axios.get(`api/v1/main-icon`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update main icon
  static updateMainIcon = async (data) => {
    try {
      const res = await axios.put(`api/v1/main-icon`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //delete main icon
  static deleteMainIcon = async (id) => {
    try {
      const res = await axios.delete(`api/v1/main-icon/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //add main icon
  static addDesign = async (data) => {
    try {
      const res = await axios.post(`api/v1/design`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get all main icons
  static getDesigns = async () => {
    try {
      const res = await axios.get(`api/v1/design`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update main icon
  static updateDesign = async (data) => {
    try {
      const res = await axios.put(`api/v1/design`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //delete main icon
  static deleteDesign = async (id) => {
    try {
      const res = await axios.delete(`api/v1/design/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //floating main icon
  static deleteFloatingIcon = async (id) => {
    try {
      const res = await axios.delete(`api/v1/floating-icon/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //add floating icon
  static addFloatingIcon = async (data) => {
    try {
      const res = await axios.post(`api/v1/floating-icon`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get all floating icons
  static getFloatingIcons = async () => {
    try {
      const res = await axios.get(`api/v1/floating-icon`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update floating icon
  static updateFloatingIcon = async (data) => {
    try {
      const res = await axios.put(`api/v1/floating-icon`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get all reviews
  static getAllUserReviews = async () => {
    try {
      const res = await axios.get(`api/v1/card/review/all`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get all languages
  static getAllLanguages = async () => {
    try {
      const res = await axios.get(`api/v1/language`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get languages by name
  static getLanguageByCode = async (name) => {
    try {
      const res = await axios.get(`api/v1//language/${name}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update language RTL
  static updateLanguageRTL = async (data) => {
    try {
      const res = await axios.put(`api/v1/language/toggle`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update language Content
  static updateLanguageContent = async (data) => {
    try {
      const res = await axios.put(`api/v1/language/content`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //add language
  static addLanguage = async (data) => {
    try {
      const res = await axios.post(`api/v1/language`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update language name
  static updateLanguageName = async (data) => {
    try {
      const res = await axios.put(`api/v1/language/name`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update Card Language
  static updateCardLanguage = async (data) => {
    try {
      const res = await axios.put(`api/v1/card/language`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get Web reports
  static getReports = async () => {
    try {
      const res = await axios.get(`api/v1/admin/reports`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  static addColumn = async (data) => {
    try {
      const res = await axios.post(`api/v1/language/column`, data);
      return res;
    } catch (error) {
      console.log(error);
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  static getImages = async () => {
    try {
      const res = await axios.get(`api/v1/images`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update images
  static updateImages = async (data) => {
    try {
      const res = await axios.put(`api/v1/images`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //add alerts
  static addAlert = async (data) => {
    try {
      const res = await axios.post(`api/v1/alert`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //get all main icons
  static getAlerts = async () => {
    try {
      const res = await axios.get(`api/v1/alert`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };
  static getAlertById = async (id) => {
    try {
      const res = await axios.get(`api/v1/alert/id/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //update main icon
  static updateAlert = async (data) => {
    try {
      const res = await axios.put(`api/v1/alert/`, data);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };
  //update main data
  static updateAlertData = async (data) => {
    try {
      const res = await axios.put(`api/v1/alert/update`, data);
      console.log("res", res);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };

  //delete main icon
  static deleteAlert = async (id) => {
    try {
      const res = await axios.delete(`api/v1/alert/${id}`);
      return res;
    } catch (error) {
      errorHandler(error.response.data);
      return error.response.data;
    }
  };
}
