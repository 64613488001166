import { useState, memo, useCallback, useEffect } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import Panel from "../layout/Panel/Panel"
import Button from "../common/Button"
import CustomModal from "../layout/modal/modal"
import Loader from "../components/loader/loader"
import { Notifications } from "../helper/notifications"
import { OVERRIDE } from "../utils/constants"
import Api from "../api/api"

const MainIcons = () => {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const iconUpdateHandle = (id, e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = async (event) => {
        setIsLoading(true)
        await Api.updateMainIcon({ id, image: event.target.result })
        getMainIcons()
      }
      reader.readAsDataURL(file)
    }
  }

  const iconDeleteHandle = async (id) => {
    await Api.deleteMainIcon(id)
    getMainIcons()
  }

  const getMainIcons = useCallback(async () => {
    const res = await Api.getMainIcons()
    if (res.status === 200) {
      setData(res.data.data)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getMainIcons()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div
              className="c_card"
              style={{ height: "100%", overflowX: "scroll" }}
            >
              <Button
                onClick={() => setShowModal(true)}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginBottom: 14,
                }}
              >
                Add Icon
              </Button>
              <table className="c_table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>ID</th>
                    <th>Main Icon</th>
                    <th style={{ width: 80 }}>Edit</th>
                    <th style={{ width: 120}}>Created at</th>
                    <th style={{ width: 120 }}>Updated at</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0
                    ? data.map((icon, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{icon.id}</td>
                          <td>
                            <img
                              width={40}
                              height={40}
                              src={icon.imageUrl}
                              alt="main icon"
                            />
                          </td>
                          <td>
                            <span>
                              <label htmlFor={`icon${icon.id}`}>
                                <input
                                  type="file"
                                  id={`icon${icon.id}`}
                                  onChange={(e) => iconUpdateHandle(icon.id, e)}
                                  style={{ display: "none" }}
                                  accept="image/jpeg, image/png, image/jpg"
                                />
                                <i className="bi bi-pencil-square"></i>
                              </label>
                            </span>
                            &nbsp;&nbsp;
                            <span onClick={() => iconDeleteHandle(icon.id)}>
                              <i className="bi bi-trash-fill"></i>
                            </span>
                          </td>
                          <td>{new Date(icon.createdAt).toLocaleString()}</td>
                          <td>{new Date(icon.updatedAt).toLocaleString()}</td>
                        </tr>
                      ))
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </Panel>
          {showModal && (
            <CustomModal
              title="Add Icon"
              onHide={() => setShowModal(false)}
            >
              <AddIcon
                getMainIcons={getMainIcons}
                onHide={() => setShowModal(false)}
              />
            </CustomModal>
          )}
        </div>
      )}
    </>
  )
}

const AddIcon = memo(({ getMainIcons, onHide }) => {
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    image: "",
  })

  const setImageHandle = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          image: event.target.result,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    const res = await Api.addMainIcon(data)
    if (res.status === 201) {
      getMainIcons()
      Notifications("success", res.data.message)
      onHide()
    }
    setIsDisable(false)
  }

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="file"
          required
          onChange={setImageHandle}
          accept="image/jpeg, image/png, image/jpg"
        />
      </div>
      {data.image && (
        <img
          src={data.image}
          width={50}
          height={50}
          alt="Icon"
        />
      )}
      <Button
        disabled={isDisable}
        type="submit"
        width="100%"
      >
        {!isDisable ? (
          "Add Icon"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  )
})
export default MainIcons
