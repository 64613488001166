import "../components/dashboard/dashboard.css"
import { useCallback, useLayoutEffect, useState } from "react"
import Panel from "../layout/Panel/Panel"
import DashboardCards from "../components/dashboard/cards"
import AreaChart from "../components/dashboard/area"
import PieChart from "../components/dashboard/pie"
import CardsTable from "../components/dashboard/cardsTable"
import UsersTable from "../components/dashboard/usersTable"
import Api from "../api/api"
import Loader from "../components/loader/loader"

const Home = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [cards, setCards] = useState([])
  const [reports, setReports] = useState({
    totalCards: 0,
    totalUsers: 0,
    activeCards: 0,
    inActiveCards: 0,
    cardsByMonth: [],
  })

  const getAllUsers = useCallback(async () => {
    const res = await Api.getAllUsers()
    if (res.status === 200) {
      setUsers(res.data.data)
    }
    setIsLoading(false)
  }, [users])

  const getUsersCard = useCallback(async () => {
    const res = await Api.getUsersCard()
    if (res.status === 200) {
      setCards(res.data.data)
    }
  }, [cards])

  const getReports = useCallback(async () => {
    const res = await Api.getReports()
    if (res.status === 200) {
      setReports(res.data.data)
    }
  }, [reports])

  useLayoutEffect(() => {
    getReports()
    getUsersCard()
    getAllUsers()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div style={{ height: "100%", overflowY: "scroll" }}>
              <DashboardCards
                cardsCount={reports?.totalCards}
                usersCount={reports?.totalUsers}
                earningsCount={0}
              />
              <div className=" dashboard_graphs">
                <AreaChart data={reports?.cardsByMonth} />
                <PieChart
                  activeCards={reports?.activeCards}
                  inActiveCards={reports?.disabledCards}
                />
              </div>
              <div className=" dashboard_tables">
                <CardsTable cards={cards?.slice(0, 5)} />
                <UsersTable users={users?.slice(0, 4)} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </>
  )
}

export default Home
