import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Panel from "../layout/Panel/Panel";
import Loader from "../components/loader/loader";
import Api from "../api/api";
import { languages } from "../data/language";

const EditLanguage = () => {
  const { name } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const inputHandle = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const getLanguageByCode = async () => {
    const res = await Api.getLanguageByCode(name);
    if (res.status === 200) {
      setData(res.data.data);
      setIsLoading(false);
    }
  };

  const updateLanguageContent = async () => {
    await Api.updateLanguageContent(data);
  };

  useLayoutEffect(() => {
    getLanguageByCode();
  }, [name]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div
              className="c_card"
              style={{ height: "100%", overflowY: "scroll" }}
            >
              <h4 style={{ textTransform: "capitalize" }}>{data?.lang_name}</h4>
              <br />
              <form className="language_form">
                <div className="c_input_wrapper">
                  <label>My Digital Cards</label>
                  <input
                    type="text"
                    value={data?.myDigitalCard}
                    name="myDigitalCard"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Account Settings</label>
                  <input
                    type="text"
                    value={data?.accSettings}
                    name="accSettings"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Appointment</label>
                  <input
                    type="text"
                    value={data?.appointment}
                    name="appointment"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Add Appointment</label>
                  <input
                    type="text"
                    value={data?.addAppointment}
                    name="addAppointment"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Select Dates</label>
                  <input
                    type="text"
                    value={data?.selectDates}
                    name="selectDates"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Book Meeting</label>
                  <input
                    type="text"
                    value={data?.bookMeeting}
                    name="bookMeeting"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Create Meeting</label>
                  <input
                    type="text"
                    value={data?.createMeeting}
                    name="createMeeting"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>View All</label>
                  <input
                    type="text"
                    value={data?.viewAll}
                    name="viewAll"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Please create new business card</label>
                  <input
                    type="text"
                    value={data?.pleaseCreateNewBusinessCard}
                    name="pleaseCreateNewBusinessCard"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Create Card</label>
                  <input
                    type="text"
                    value={data?.createCard}
                    name="createCard"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Create New Card</label>
                  <input
                    type="text"
                    value={data?.createNewCard}
                    name="createNewCard"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Card Design</label>
                  <input
                    type="text"
                    value={data?.cardDesign}
                    name="cardDesign"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Logo</label>
                  <input
                    type="text"
                    value={data?.logo}
                    name="logo"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Business Logo</label>
                  <input
                    type="text"
                    value={data?.businessLogo}
                    name="businessLogo"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Cover Photo</label>
                  <input
                    type="text"
                    value={data?.coverPhoto}
                    name="coverPhoto"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Sort at</label>
                  <input
                    type="text"
                    value={data?.sortAt}
                    name="sortAt"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Background Image</label>
                  <input
                    type="text"
                    value={data?.backgroundImage}
                    name="backgroundImage"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Business Name</label>
                  <input
                    type="text"
                    value={data?.businessName}
                    name="businessName"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Title</label>
                  <input
                    type="text"
                    value={data?.title}
                    name="title"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Slogan</label>
                  <input
                    type="text"
                    value={data?.slogan}
                    name="slogan"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Color</label>
                  <input
                    type="text"
                    value={data?.color}
                    name="color"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Cover Color</label>
                  <input
                    type="text"
                    value={data?.coverColor}
                    name="coverColor"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Background Color</label>
                  <input
                    type="text"
                    value={data?.backgroundColor}
                    name="backgroundColor"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Like Button</label>
                  <input
                    type="text"
                    value={data?.likeButton}
                    name="likeButton"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Floating Button</label>
                  <input
                    type="text"
                    value={data?.floatingButton}
                    name="floatingButton"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Main Icons</label>
                  <input
                    type="text"
                    value={data?.mainIcons}
                    name="mainIcons"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Add Icons</label>
                  <input
                    type="text"
                    value={data?.addIcons}
                    name="addIcons"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Icon used for?</label>
                  <input
                    type="text"
                    value={data?.iconUsedFor}
                    name="iconUsedFor"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Whatsapp Number</label>
                  <input
                    type="text"
                    value={data?.whatsappNumber}
                    name="whatsappNumber"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>SMS Number</label>
                  <input
                    type="text"
                    value={data?.smsNumber}
                    name="smsNumber"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    value={data?.phoneNumber}
                    name="phoneNumber"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Mail Address</label>
                  <input
                    type="text"
                    value={data?.mailAddress}
                    name="mailAddress"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Link Address</label>
                  <input
                    type="text"
                    value={data?.linkAddress}
                    name="linkAddress"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Select</label>
                  <input
                    type="text"
                    value={data?.select}
                    name="select"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Opening Hours</label>
                  <input
                    type="text"
                    value={data?.openingHours}
                    name="openingHours"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Monday</label>
                  <input
                    type="text"
                    value={data?.monday}
                    name="monday"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Tuesday</label>
                  <input
                    type="text"
                    value={data?.tuesday}
                    name="tuesday"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Wednesday</label>
                  <input
                    type="text"
                    value={data?.wednesday}
                    name="wednesday"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Thursday</label>
                  <input
                    type="text"
                    value={data?.thursday}
                    name="thursday"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Friday</label>
                  <input
                    type="text"
                    value={data?.friday}
                    name="friday"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Saturday</label>
                  <input
                    type="text"
                    value={data?.saturday}
                    name="saturday"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Sunday</label>
                  <input
                    type="text"
                    value={data?.sunday}
                    name="sunday"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Gallery</label>
                  <input
                    type="text"
                    value={data?.gallery}
                    name="gallery"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Add Picture</label>
                  <input
                    type="text"
                    value={data?.addPicture}
                    name="addPicture"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Upload</label>
                  <input
                    type="text"
                    value={data?.upload}
                    name="upload"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Reviews</label>
                  <input
                    type="text"
                    value={data?.reviews}
                    name="reviews"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Add Review</label>
                  <input
                    type="text"
                    value={data?.addReviews}
                    name="addReviews"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Edit Review</label>
                  <input
                    type="text"
                    value={data?.editReviews}
                    name="editReviews"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Rate your experience with</label>
                  <input
                    type="text"
                    value={data?.rateYourExperienceWith}
                    name="rateYourExperienceWith"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Name</label>
                  <input
                    type="text"
                    value={data?.name}
                    name="name"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Your Experience</label>
                  <input
                    type="text"
                    value={data?.yourExperience}
                    name="yourExperience"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Floating Number</label>
                  <input
                    type="text"
                    value={data?.floatingNumber}
                    name="floatingNumber"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Edit Icon</label>
                  <input
                    type="text"
                    value={data?.editIcon}
                    name="editIcon"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>General Settings</label>
                  <input
                    type="text"
                    value={data?.generalSettings}
                    name="generalSettings"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Add to Home Screen</label>
                  <input
                    type="text"
                    value={data?.addHome}
                    name="addHome"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Click on at the bottom of the screen</label>
                  <input
                    type="text"
                    value={data?.clickBottom}
                    name="clickBottom"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Scroll down and click on</label>
                  <input
                    type="text"
                    value={data?.scrollDown}
                    name="scrollDown"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Click on at the corner of the screen</label>
                  <input
                    type="text"
                    value={data?.clickCorner}
                    name="clickCorner"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Click on</label>
                  <input
                    type="text"
                    value={data?.clickOn}
                    name="clickOn"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Preview</label>
                  <input
                    type="text"
                    value={data?.preview}
                    name="preview"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Add Contact</label>
                  <input
                    type="text"
                    value={data?.addContact}
                    name="addContact"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Share</label>
                  <input
                    type="text"
                    value={data?.share}
                    name="share"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Update Profile</label>
                  <input
                    type="text"
                    value={data?.updateProfile}
                    name="updateProfile"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Email Address</label>
                  <input
                    type="text"
                    value={data?.emailAddress}
                    name="emailAddress"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Password</label>
                  <input
                    type="text"
                    value={data?.password}
                    name="password"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Current Password</label>
                  <input
                    type="text"
                    value={data?.currentPassword}
                    name="currentPassword"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>New Password</label>
                  <input
                    type="text"
                    value={data?.newPassword}
                    name="newPassword"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Confirm Password</label>
                  <input
                    type="text"
                    value={data?.confirmPassword}
                    name="confirmPassword"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Change Password</label>
                  <input
                    type="text"
                    value={data?.changePassword}
                    name="changePassword"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Forget Password</label>
                  <input
                    type="text"
                    value={data?.forgetPassword}
                    name="forgetPassword"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Forget</label>
                  <input
                    type="text"
                    value={data?.forget}
                    name="forget"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Reset</label>
                  <input
                    type="text"
                    value={data?.reset}
                    name="reset"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Already have account?</label>
                  <input
                    type="text"
                    value={data?.alreadyHaveAccount}
                    name="alreadyHaveAccount"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Logout</label>
                  <input
                    type="text"
                    value={data?.logout}
                    name="logout"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Login</label>
                  <input
                    type="text"
                    value={data?.login}
                    name="login"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Signup</label>
                  <input
                    type="text"
                    value={data?.signup}
                    name="signup"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Don't Have Account</label>
                  <input
                    type="text"
                    value={data?.dontHaveAccount}
                    name="dontHaveAccount"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Reset Password</label>
                  <input
                    type="text"
                    value={data?.resetPassword}
                    name="resetPassword"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Reset Code</label>
                  <input
                    type="text"
                    value={data?.resetCode}
                    name="resetCode"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Sr.</label>
                  <input
                    type="text"
                    value={data?.sr}
                    name="sr"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>URL</label>
                  <input
                    type="text"
                    value={data?.url}
                    name="url"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Status</label>
                  <input
                    type="text"
                    value={data?.status}
                    name="status"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Action</label>
                  <input
                    type="text"
                    value={data?.action}
                    name="action"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Approved</label>
                  <input
                    type="text"
                    value={data?.approved}
                    name="approved"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Rating</label>
                  <input
                    type="text"
                    value={data?.rating}
                    name="rating"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Language</label>
                  <input
                    type="text"
                    value={data?.language}
                    name="language"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>QR Code</label>
                  <input
                    type="text"
                    value={data?.qrCode}
                    name="qrCode"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                <div className="c_input_wrapper">
                  <label>Open Camera and Scan QR Code</label>
                  <input
                    type="text"
                    value={data?.openCameraScanCode}
                    name="openCameraScanCode"
                    onChange={inputHandle}
                    onBlur={updateLanguageContent}
                  />
                </div>
                {languages.map((item, index) => {
                  return (
                    <div className="c_input_wrapper">
                      <label>{item.label}</label>
                      <input
                        type="text"
                        value={data?.[item.name]}
                        name={item.name}
                        onChange={inputHandle}
                        onBlur={updateLanguageContent}
                      />
                    </div>
                  );
                })}
              </form>
            </div>
          </Panel>
        </div>
      )}
    </>
  );
};

export default EditLanguage;
