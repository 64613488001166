import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import Panel from "../layout/Panel/Panel";
import CustomModal from "../layout/modal/modal";
import Button from "../common/Button";

export default function Testimonial() {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [user, setUser] = useState({
    name: "",
    company: "",
    description: "",
  });
  const [testimonials, setTestimonials] = useState({
    name: "",
    company: "",
    description: "",
    id: "",
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const getData = async () => {
    await axios
      .get("api/v1/testimonial")
      .then((res) => {
        console.log(res);
        setData(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTestimonial = async (e) => {
    e.preventDefault();

    await axios
      .put(`api/v1/testimonial/${testimonials.id}`, testimonials)
      .then((res) => {
        getData();
        setEditModal(false);
      });
  };

  const addTestimonial = async (e) => {
    console.log(user);
    e.preventDefault();
    await axios
      .post("api/v1/testimonial", user)
      .then((res) => {
        getData();
        setAddModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTestimonial = async (id) => {
    await axios
      .delete(`api/v1/testimonial/${id}`)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Panel>
        <div className="m-5 p-5 flex flex-col ">
          <div className="mb-3">
            <Button
              onClick={() => setAddModal(true)}
              style={{
                marginLeft: "auto",
                marginBottom: 14,
              }}
            >
              Add Testimonial
            </Button>
          </div>
          <hr />
          <div>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200  ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Company
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr
                      className="bg-white border-b  "
                      key={item.name}
                      onClick={() => {
                        setEditModal(true);
                        setTestimonials(item);
                      }}
                    >
                      <td className="px-6 py-4">{item.name}</td>
                      <td className="px-6 py-4">{item.company}</td>
                      <td className="px-6 py-4">{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          ​
          {addModal && (
            <CustomModal
              title="Add New Testimonial"
              onHide={() => setAddModal(false)}
            >
              <form onSubmit={addTestimonial}>
                <div className="mb-3">
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={user.name}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="John Doe"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="company"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Company
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    value={user.company}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="SS Developers"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label
                    for="description"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Description
                  </label>
                  <textarea
                    rows="3"
                    name="description"
                    id="description"
                    value={user.description}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="He is the best seller here"
                    required
                  />
                </div>

                <Button
                  style={{
                    marginLeft: "auto",
                    marginBottom: 14,
                    width: "100%",
                  }}
                >
                  Add Testimonial
                </Button>
              </form>
            </CustomModal>
          )}
          {editModal && (
            <div
              className="fixed flex flex-row  justify-center items-center   top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
              tabIndex="-1"
              aria-hidden="true"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow ">
                  <button
                    type="button"
                    onClick={() => setEditModal(false)}
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                    data-modal-hide="authentication-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="px-6 py-6 lg:px-8 ">
                    <h3 className="mb-4 text-xl font-medium text-gray-900  text-center">
                      Edit Testimonial
                    </h3>

                    <form className="" action="#" onSubmit={updateTestimonial}>
                      <div className="mb-3">
                        <label
                          for="name"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={testimonials.name}
                          onChange={(e) =>
                            setTestimonials({
                              ...testimonials,
                              name: e.target.value,
                            })
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder="John Doe"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          for="company"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Company
                        </label>
                        <input
                          type="text"
                          name="company"
                          id="company"
                          value={testimonials.company}
                          onChange={(e) =>
                            setTestimonials({
                              ...testimonials,
                              company: e.target.value,
                            })
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder="SS Developers"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          for="description"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Description
                        </label>
                        <textarea
                          rows="3"
                          name="description"
                          id="description"
                          value={testimonials.description}
                          onChange={(e) =>
                            setTestimonials({
                              ...testimonials,
                              description: e.target.value,
                            })
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder="He is the best seller here"
                          required
                        />
                      </div>

                      <Button
                        style={{
                          marginLeft: "auto",
                          marginBottom: 14,
                          width: "100%",
                        }}
                      >
                        Update
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
}
