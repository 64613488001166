import "./auth.css"

const Auth = ({ title, children }) => {
  return (
    <div className="auth_page">
      <div className="auth_cont">
        <div className="auth_child2">
          <div className="title_1">{title}</div>
          <br />
          {children}
        </div>
      </div>
    </div>
  )
}

export default Auth
