import { Link } from "react-router-dom"
import users from "../../assets/users.svg"
import cards from "../../assets/cards.svg"
import earnings from "../../assets/earnings.svg"

const DashboardCards = ({ cardsCount, usersCount, earningsCount }) => {
  return (
    <div className="dashboard_cards">
      <Link
        to="/users"
        className="c_card"
      >
        <div className="card_icon red">
          <img
            src={users}
            alt=""
          />
        </div>
        <div>
          <p>{usersCount}</p>
          <span>Total Users</span>
        </div>
      </Link>
      <Link
        to="/cards"
        className="c_card"
      >
        <div className="card_icon green">
          <img
            src={cards}
            alt=""
          />
        </div>
        <div>
          <p>{cardsCount}</p>
          <span>Total Cards</span>
        </div>
      </Link>
      <Link
        to="/earnings"
        className="c_card"
      >
        <div className="card_icon golden">
          <img
            src={earnings}
            alt=""
          />
        </div>
        <div>
          <p>${earningsCount}</p>
          <span>Total Earnings</span>
        </div>
      </Link>
    </div>
  )
}

export default DashboardCards
