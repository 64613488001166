import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ClipLoader from "react-spinners/ClipLoader"
import Button from "../../../common/Button"
import CustomModal from "../../../layout/modal/modal"
import DesignCard from "../../../layout/designCard/designCard"
import {
  deletePicture,
  designCard,
  editPicture,
  sortGallery,
} from "../../../store/slices/cardSlice"
import Api from "../../../api/api"
import { OVERRIDE } from "../../../utils/constants"

const Gallery = ({ toggle, setToggle }) => {
  const { galleries: gallery, id } = useSelector((state) => state.card)
  const [showModal, setShowModal] = useState(false)
  return (
    <DesignCard
      title="Gallery"
      toggle={toggle}
      onShow={() => setToggle(4, !toggle)}
    >
      {gallery.length < 10 && (
        <Button onClick={() => setShowModal(true)}>
          Add Picture
        </Button>
      )}
      {gallery.length > 0 &&
        gallery.map(({ id, image }, index) => (
          <Picture
            id={id}
            image={image}
            index={index}
            count={gallery.length}
          />
        ))}
      {showModal && (
        <AddPicture
          cid={id}
          onHide={() => setShowModal(false)}
        />
      )}
    </DesignCard>
  )
}

const Picture = ({ id, image, index, count }) => {
  const dispatch = useDispatch()
  const [viewPicture, setViewPicture] = useState(false)

  const imageHandler = async (e) => {
    const { files } = e.target
    if (!files[0]) return
    const reader = new FileReader()
    reader.onload = async (event) => {
      dispatch(editPicture({ id, image: event.target.result }))

      const formData = {
        id: id,
        image: event.target.result,
      }
      await Api.editGallery(formData)
    }
    reader.readAsDataURL(files[0])
  }

  const deletePic = async () => {
    dispatch(deletePicture(id))
    await Api.deleteGallery(id)
  }

  return (
    <div
      className="gallery_wrapper"
      key={id}
    >
      <div className="gw1">
        <img
          src={image}
          alt="Gallery Picture_"
          loading="lazy"
          decoding="async"
        />
        <div className="galley_actions">
          <span onClick={() => setViewPicture(true)}>
            <i className="bi bi-eye"></i>
          </span>
          <label htmlFor="gallery_image">
            <span>
              <i className="bi bi-pencil-square"></i>
            </span>
            <input
              type="file"
              id="gallery_image"
              accept="image/*"
              onChange={imageHandler}
            />
          </label>
          <span onClick={deletePic}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      </div>
      <div className="gw2">
        <span>Sort at</span>
        <select
          value={index + 1}
          onChange={(e) =>
            dispatch(
              sortGallery({ from: index, to: parseInt(e.target.value) - 1 }),
            )
          }
        >
          {Array(count)
            .fill()
            .map((_, i) => (
              <option
                disabled={i === index}
                value={i + 1}
              >
                {i + 1}
              </option>
            ))}
        </select>
      </div>
      {viewPicture && (
        <ViewPicture
          image={image}
          onHide={() => setViewPicture(false)}
        />
      )}
    </div>
  )
}

const AddPicture = ({ onHide, cid }) => {
  const dispatch = useDispatch()
  const { title } = useParams()
  const [image, setImage] = useState("")
  const [isDisable, setIsDisable] = useState(false)

  const imageHandler = (file) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setImage(event.target.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const uploadHandle = async () => {
    if (image) {
      setIsDisable(true)
      // dispatch(addPicture({ id: Date.now(), image }))

      const formData = {
        cid: cid,
        image: image,
      }
      const res = await Api.addGallery(formData)
      if (res.status === 201) {
        fetchCardData()
        setIsDisable(false)
        onHide()
      } else {
        setIsDisable(false)
      }
    }
  }

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCard(title)
    if (res.status === 200) {
      dispatch(designCard(res.data.data))
      onHide()
    }
  }, [])

  return (
    <CustomModal
      title="Add Picture"
      onHide={onHide}
    >
      {image && (
        <img
          src={image}
          style={{ width: "100%", objectFit: "cover", aspectRatio: 4 / 3 }}
          alt="add gallery"
        />
      )}
      <input
        type="file"
        onChange={(e) => imageHandler(e.target.files[0])}
        style={{ marginBottom: 6 }}
        accept="image/*"
      />
      <Button
        disabled={isDisable}
        width="100%"
        onClick={uploadHandle}
      >
        {!isDisable ? (
          "Upload"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </CustomModal>
  )
}

const ViewPicture = ({ onHide, image }) => {
  return (
    <CustomModal
      title="Gallery"
      isBgShow={false}
      onHide={onHide}
    >
      {image && (
        <img
          src={image}
          style={{ width: "100%", objectFit: "cover" }}
          alt="View gallery"
        />
      )}
    </CustomModal>
  )
}

export default Gallery
