import { useCallback, useLayoutEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { designCard } from "../store/slices/cardSlice"
import Api from "../api/api"
import CardPreview from "../components/preview/preview"
import Loader from "../components/loader/loader"
import { langLoader } from "../store/slices/langSlice"

const PreviewCard = () => {
  const { title } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [isCard, setIsCard] = useState(false)

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCardPublic(title)
    if (res.status === 200) {
      if (res.data.data.language.rtl) {
        document.querySelector("body").classList.add("preview_rtl")
      }
      dispatch(designCard(res.data.data))
      setIsCard(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (res.status === 404) {
      setIsCard(false)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [])

  useLayoutEffect(() => {
    dispatch(langLoader(false))
    fetchCardData()

    return () => {
      document.querySelector("body").classList.remove("preview_rtl")
      document.querySelector("body").classList.remove("rtl")
    }
  }, [title])

  return (
    <>
      {isLoading && <Loader />}
      {!isCard ? (
        "No Card Exists"
      ) : (
        <>
          <div className="preview_page">
            <CardPreview />
          </div>
        </>
      )}
    </>
  )
}

export default PreviewCard
