import { useCallback, useEffect, useState } from "react"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import PhoneInput from "react-phone-number-input"
import { parsePhoneNumber } from "libphonenumber-js"
import { useDispatch, useSelector } from "react-redux"
import DesignCard from "../../../layout/designCard/designCard"
import AddIcons from "./addIcons"
import EditIcons from "./editIcons"
import Button from "../../../common/Button"
import { deleteIcon, editIcon, sortIcon } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const MainIcons = ({ toggle, setToggle }) => {
  const dispatch = useDispatch()
  const { icons, id } = useSelector((state) => state.card)
  const [iconList, setIconList] = useState([])
  const [iconData, setIconData] = useState({
    id: 0,
    type: "",
    icon: "",
    value: "",
  })
  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const editIconHandle = async (data) => {
    setIconData(data)
    setEditModal(true)
  }

  const deleteIconHandle = async (index) => {
    dispatch(deleteIcon(index))
  }

  const onValueChange = async (data) => {
    dispatch(editIcon(data))
  }

  const onBlurChange = async (id, value) => {
    if (value.includes("+")) {
      const parsedPhoneNumber = parsePhoneNumber(value)
      if (!parsedPhoneNumber.isValid()) return alert("Invalid number")
    }

    await Api.updateIconValue({ id, value })
  }

  const getMainIconsList = useCallback(async () => {
    const res = await Api.getMainIcons()
    if (res.status === 200) {
      setIconList(res.data.data)
    }
  }, [setIconList])

  useEffect(() => {
    getMainIconsList()
  }, [])

  return (
    <DesignCard
      title="Main Icons"
      toggle={toggle}
      onShow={() => setToggle(2, !toggle)}
    >
      {icons.length < 10 && (
        <Button onClick={() => setAddModal(true)}>Add Icons</Button>
      )}
      {icons.length > 0 &&
        icons.map(({ id, type, value, icon }, index) =>
          type === "sms" || type === "wa" || type === "phn" ? (
            <Number
              placeholder={
                type === "wa"
                  ? "Whatsapp Number"
                  : type === "sms"
                  ? "SMS Number"
                  : "Phone Number"
              }
              id={id}
              index={index}
              count={icons.length}
              icon={icon}
              value={value}
              type={type}
              onDelete={deleteIconHandle}
              onChange={onValueChange}
              onBlur={onBlurChange}
              onEdit={() => editIconHandle({ id, type, value, icon })}
            />
          ) : (
            <Link
              inputType={type === "mail" ? "email" : "text"}
              placeholder={
                type === "mail"
                  ? "Mail Address"
                  : "https://www.example.com"
              }
              id={id}
              index={index}
              count={icons.length}
              icon={icon}
              value={value}
              type={type}
              onDelete={deleteIconHandle}
              onChange={onValueChange}
              onBlur={onBlurChange}
              onEdit={() => editIconHandle({ id, type, value, icon })}
            />
          ),
        )}
      {addModal && (
        <AddIcons
          id={id}
          data={iconList}
          onHide={() => setAddModal(false)}
        />
      )}
      {editModal && (
        <EditIcons
          data={iconList}
          iconData={iconData}
          onHide={() => setEditModal(false)}
        />
      )}
    </DesignCard>
  )
}

const Link = ({
  id,
  index,
  count,
  type,
  value,
  icon,
  inputType,
  placeholder,
  onEdit,
  onDelete,
  onChange,
  onBlur,
}) => {
  const dispatch = useDispatch()
  return (
    <div
      className="design_wrapper"
      key={id}
    >
      <div>
        <div className="icon_wrapper">
          <img
            onClick={onEdit}
            src={icon}
            alt=""
          />
        </div>
        <div className="main_icon_inputs">
          <input
            type={inputType}
            value={value}
            onChange={(e) =>
              onChange({ id, type, value: e.target.value, icon })
            }
            onBlur={(e) => onBlur(id, e.target.value)}
            placeholder={placeholder}
          />
        </div>
        <div
          className="icon_toggle"
          onClick={async () => [onDelete(id), await Api.deleteIcon(id)]}
        >
          <i className="bi bi-trash"></i>
        </div>
      </div>
      <div className="sorting_select">
        <span>Sort at</span>
        <select
          value={index + 1}
          onChange={(e) =>
            dispatch(
              sortIcon({ from: index, to: parseInt(e.target.value) - 1 }),
            )
          }
        >
          {Array(count)
            .fill()
            .map((_, i) => (
              <option
                disabled={i === index}
                value={i + 1}
              >
                {i + 1}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

const Number = ({
  id,
  index,
  count,
  type,
  value,
  icon,
  placeholder,
  sortAt,
  onEdit,
  onDelete,
  onChange,
  onBlur,
}) => {
  const dispatch = useDispatch()
  const { name } = useParams()
  const countryCode = useMemo(() => {
    if (name) {
      const parts = name.split(".")
      const code = parts[parts.length - 1]
      return code.toUpperCase()
    }
  }, [name])

  return (
    <div
      className="design_wrapper"
      key={id}
    >
      <div>
        <div className="icon_wrapper">
          <img
            onClick={onEdit}
            src={icon}
            alt=""
          />
        </div>
        <div className="main_icon_inputs">
          <PhoneInput
            value={value}
            onChange={(e) => onChange({ id, type, value: e, icon })}
            onBlur={(e) => onBlur(id, e.target.value)}
            placeholder={placeholder}
            defaultCountry={countryCode}
            international
            required
          />
        </div>
        <div
          className="icon_toggle"
          onClick={async () => [onDelete(id), await Api.deleteIcon(id)]}
        >
          <i className="bi bi-trash"></i>
        </div>
      </div>
      <div className="sorting_select">
        <span>{sortAt}</span>
        <select
          value={index + 1}
          onChange={(e) =>
            dispatch(
              sortIcon({ from: index, to: parseInt(e.target.value) - 1 }),
            )
          }
        >
          {Array(count)
            .fill()
            .map((_, i) => (
              <option
                disabled={i === index}
                value={i + 1}
              >
                {i + 1}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

export default MainIcons
