import "./Panel.css";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";

const Panel = ({ children }) => {
  const toggleSidebar = useSelector((state) => state.sidebar);
  return (
    <section className="panel" id="panel">
      <div className="panel_cont">
        <Sidebar />
        <div
          className={`dashboard ${
            toggleSidebar ? "panel_move" : "panel_no_move"
          }`}
        >
          <Header />
          <div className="dashboard_cont">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default Panel;
