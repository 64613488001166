import { memo } from "react"
import { Link } from "react-router-dom"

const CardsTable = ({ cards }) => {
  return (
    <div className="c_card">
      <Head />
      <div className="dashboard_table p">
        <table className="cards_table">
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ID</th>
              <th style={{ minWidth: 100 }}>Title</th>
              <th style={{ minWidth: 50 }}>Views</th>
              <th style={{ minWidth: 45 }}>Likes</th>
              <th style={{ minWidth: 70 }}>Device</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {cards && cards.length > 0
              ? cards.map((card) => (
                  <tr key={card.id}>
                    <td>{card.id}</td>
                    <td>{card.title}</td>
                    <td>{card.views}</td>
                    <td>{card.likes}</td>
                    <td>{card.deviceName}</td>
                    <td>
                      <span
                        className={`status ${
                          card.status === "active" ? "active" : "expired"
                        }`}
                      >
                        {card.status}
                      </span>
                    </td>
                  </tr>
                ))
              : "No data available"}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const Head = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p style={{ fontWeight: 600 }}>Cards</p>
      <Link
        to="/cards"
        style={{ color: "var(--primary)", fontSize: 14 }}
      >
        View all
      </Link>
    </div>
  )
}

export default memo(CardsTable)
