import * as React from "react";
import { useState, memo, useCallback, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Panel from "../layout/Panel/Panel";
import Button from "../common/Button";
import AlertCustomModal from "../layout/modal/alertAdd";
import Loader from "../components/loader/loader";
import { Notifications } from "../helper/notifications";
import { OVERRIDE } from "../utils/constants";
import Api from "../api/api";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import EditAlert from "../components/alert/editAlert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MainIcons = () => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const iconUpdateHandle = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        setIsLoading(true);
        const res = await Api.updateAlert({ id, image: event.target.result });
        Notifications("success", res.data.message);
        getAlerts();
      };
      reader.readAsDataURL(file);
    }
  };
  const getAllUsers = useCallback(async () => {
    const res = await Api.getAllUsers();
    if (res.status === 200) {
      setUsers(res.data.data);
      setIsLoading(false);
    }
  }, [setUsers]);

  const iconDeleteHandle = async (id) => {
    const res = await Api.deleteAlert(id);
    Notifications("success", res.data.message);
    getAlerts();
  };

  const getAlerts = useCallback(async () => {
    const res = await Api.getAlerts();
    if (res.status === 200) {
      setData(res.data.data);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAlerts();
    getAllUsers();
  }, []);
  const edit = async (show, item) => {
    console.log(show, item);
    const res = await Api.getAlertById(item);
    console.log("res", res);
    setEditingItem(res.data.data);
    setShowEditModal(show);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div
              className="c_card"
              style={{ height: "100%", overflowX: "scroll" }}
            >
              <Button
                onClick={() => setShowModal(true)}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  margin: "14px 0 14px auto",
                }}
              >
                Add Alert
              </Button>
              <table className="c_table">
                <thead>
                  <tr>
                    <th style={{ width: 40 }}>Sr.</th>
                    <th style={{ width: 80 }}>Image</th>
                    <th>Description</th>
                    <th>Url</th>
                    <th style={{ width: 160 }}>User</th>
                    <th style={{ width: 120 }}>Date</th>
                    <th style={{ width: 40 }}>Count</th>
                    <th style={{ width: 80 }}>Edit</th>
                    <th style={{ width: 120 }}>Created at</th>
                    <th style={{ width: 120 }}>Updated at</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0
                    ? data.map((icon, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="flex gap-1 items-end">
                              <img
                                width={40}
                                height={40}
                                src={icon.imageUrl}
                                alt="main icon"
                              />
                              <span>
                                <label htmlFor={`icon${icon.id}`}>
                                  <input
                                    type="file"
                                    id={`icon${icon.id}`}
                                    onChange={(e) =>
                                      iconUpdateHandle(icon.id, e)
                                    }
                                    style={{ display: "none" }}
                                    accept="image/jpeg, image/png, image/jpg"
                                  />
                                  <i className="bi bi-pencil-square"></i>
                                </label>
                              </span>
                            </div>
                          </td>
                          <td>{icon.description}</td>
                          <td>{icon.url}</td>
                          <td>
                            {users
                              .filter((user) => user.id === icon.user_id)
                              .map((matchedUser) => (
                                <p key={matchedUser.id}>{matchedUser.name}</p>
                              ))}
                          </td>
                          <td>{icon.date}</td>
                          <td>{icon.count}</td>
                          <td>
                            <span>
                              <label onClick={() => edit(true, icon.id)}>
                                <i className="bi bi-pencil-square"></i>
                              </label>
                            </span>
                            &nbsp;&nbsp;
                            <span onClick={() => iconDeleteHandle(icon.id)}>
                              <i className="bi bi-trash-fill"></i>
                            </span>
                          </td>
                          <td>{new Date(icon.createdAt).toLocaleString()}</td>
                          <td>{new Date(icon.updatedAt).toLocaleString()}</td>
                        </tr>
                      ))
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </Panel>
          {showModal && (
            <AlertCustomModal
              title="Add Alert"
              onHide={() => setShowModal(false)}
            >
              <AddImage
                getAlerts={getAlerts}
                onHide={() => setShowModal(false)}
                users={users}
              />
            </AlertCustomModal>
          )}
          {showEditModal && (
            <AlertCustomModal
              title="Add Alert"
              onHide={() => setShowEditModal(false)}
            >
              <EditAlert
                editingItem={editingItem}
                getAlerts={getAlerts}
                onHide={() => setShowEditModal(false)}
                users={users}
              />
            </AlertCustomModal>
          )}
        </div>
      )}
    </>
  );
};

const AddImage = memo(({ getAlerts, onHide, users }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [personName, setPersonName] = React.useState([]);
  const [dates, setDates] = useState([]); // State for dates
  const [dateCount, setDateCount] = useState(0); // Counter for dates
  const [data, setData] = useState({
    image: "",
    description: "",
    url: "",
    user_id: [],
    date_list: [],
    count: "",
  });

  const setImageHandle = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          image: event.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    const res = await Api.addAlert({ ...data, date_list: dates });
    if (res.status === 201) {
      getAlerts();
      Notifications("success", res.data.message);
      onHide();
    }
    setIsDisable(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    let newUserIds = [];
    const isSelectAllChecked = value.includes("all");

    if (isSelectAllChecked) {
      if (personName.length === users.length) {
        newUserIds = []; // Deselect all
      } else {
        newUserIds = users.map((user) => user.id);
      }
    } else {
      newUserIds = typeof value === "string" ? value.split(",") : value;
    }
    console.log("newUserIds", newUserIds);
    setPersonName(newUserIds);

    setData((prevState) => ({
      ...prevState,
      user_id: newUserIds,
    }));
  };
  const handleDateChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedDate = event.target.value;
    if (dateCount < 3) {
      setDates([...dates, selectedDate]);
      setDateCount(dateCount + 1);
    } else {
      Notifications("warning", "Maximum of 3 dates can be selected.");
    }
  };
  const removeDate = (indexToRemove) => {
    setDates(dates.filter((_, index) => index !== indexToRemove));
    setDateCount((prevCount) => Math.max(prevCount - 1, 0)); // Decrease dateCount by 1, but not below 0
  };
  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="file"
          required
          onChange={setImageHandle}
          accept="image/jpeg, image/png, image/jpg"
        />
        {data.image && (
          <img
            src={data.image}
            width={50}
            height={50}
            alt="Icon"
            className="mt-3"
          />
        )}
        <div className="my-3">
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Description
          </label>
          <input
            type="text"
            name="description"
            id="description"
            value={data.description}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
        </div>
        <div className="my-3">
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Url
          </label>
          <input
            type="text"
            name="url"
            id="url"
            value={data.url}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
        </div>
        <div>
          <FormControl sx={{ m: 1 }} className="w-full !m-0">
            <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={personName}
              onChange={handleUserChange}
              input={<OutlinedInput label="Users" />}
              renderValue={(selected) =>
                selected
                  .map((id) => users.find((user) => user.id === id)?.name)
                  .join(", ")
              }
              MenuProps={MenuProps}
              required
            >
              <MenuItem value="all">
                <Checkbox checked={personName.length === users.length} />
                <ListItemText primary="Select All" />
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <Checkbox checked={personName.indexOf(user.id) > -1} />
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <label
            htmlFor="date"
            className="block mb-2 mt-3 text-sm font-medium text-gray-900"
          >
            Select Dates (up to 3):
          </label>
          <label
            htmlFor="date"
            className="block mb-2 mt-3 text-sm font-medium text-gray-900"
          >
            <ul>
              {dates.map((date, index) => (
                <li key={index}>
                  {date}
                  <span onClick={() => removeDate(index)} className="ml-4">
                    <i className="bi bi-x-lg w-2"></i>
                  </span>
                </li>
              ))}
            </ul>
          </label>
          <input
            type="date"
            id="date"
            onChange={handleDateChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
          {dateCount >= 3 && (
            <p className="text-red-500">
              You've reached the maximum number of dates.
            </p>
          )}
        </div>
        <div className="my-3">
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Count
          </label>
          <input
            type="number"
            name="count"
            id="count"
            value={data.count}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
        </div>
      </div>
      <Button disabled={isDisable} type="submit" width="100%">
        {!isDisable ? (
          "Add Alert"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});
export default MainIcons;
