import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import Auth from "../layout/auth/auth"
import Button from "../common/Button"
import Api from "../api/api"
import ClipLoader from "react-spinners/ClipLoader"
import { OVERRIDE } from "../utils/constants"
import { addUser } from "../store/slices/userSlice"
import { Notifications } from "../helper/notifications"

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({ email: "", password: "" })

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    const res = await Api.userLogin(data)
    if (res.status === 200) {
      if (res.data.user.type === "admin") {
        dispatch(addUser(res.data.user))
        history.push("/")
        Notifications("success", res.data.message)
      } else {
        Notifications("error", "Unauthorized user")
      }
    }
    setIsDisable(false)
  }

  return (
    <Auth title="Login">
      <form
        onSubmit={submitHandle}
        className="auth_form"
      >
        <div className="c_input_wrapper">
          <input
            type="email"
            name="email"
            value={data.email}
            onChange={inputHandle}
            placeholder="Email"
            required
          />
        </div>
        <div className="c_input_wrapper">
          <input
            type="password"
            name="password"
            value={data.password}
            onChange={inputHandle}
            placeholder="Password"
            required
          />
        </div>

        <Button
          disabled={isDisable}
          className="btn_primary"
        >
          {!isDisable ? (
            "Login"
          ) : (
            <ClipLoader
              color="#FFFFFF"
              loading={true}
              cssOverride={OVERRIDE}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </Button>
      </form>
    </Auth>
  )
}

export default Login
