import { memo, useLayoutEffect, useEffect, useState, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import MUIDataTable from "mui-datatables";
import createCache from "@emotion/cache";
import Loader from "../components/loader/loader";
import Panel from "../layout/Panel/Panel";
import CustomModal from "../layout/modal/modal";
import Button from "../common/Button";
import Api from "../api/api";
import { OVERRIDE } from "../utils/constants";
import ClipLoader from "react-spinners/ClipLoader";
import { Notifications } from "../helper/notifications";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showPassModal, setShowPassModal] = useState(false);
  const [data, setData] = useState([]);
  const [editUser, setEditUser] = useState({ id: 0, status: "active" });
  const [editRole, setEditRole] = useState({ id: 0, type: "user" });
  const [editPass, setEditPass] = useState({ id: 0 });

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  };

  const columns = [
    {
      name: "id",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "avatar",
      label: "Avatar",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              src={tableMeta.rowData[1]}
              style={{
                width: 40,
                height: 40,
                borderRadius: "100%",
                objectFit: "cover",
              }}
              alt=""
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "emailVerified",
      label: "Is Verified",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Active data={tableMeta} index={4} text={true} />;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Active data={tableMeta} index={5} text="active" />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditUser({
                    id: tableMeta.rowData[0],
                    status: tableMeta.rowData[5],
                  });
                  setShowModal(true);
                }}
              >
                <i className="bi bi-pencil-fill"></i>
              </span>
            </p>
          );
        },
      },
    },
    {
      name: "type",
      label: "Role",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Active data={tableMeta} index={6} text="user" />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditRole({
                    id: tableMeta.rowData[0],
                    type: tableMeta.rowData[6],
                  });
                  setShowRoleModal(true);
                }}
              >
                <i className="bi bi-pencil-fill"></i>
              </span>
            </p>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditPass({
                    id: tableMeta.rowData[0],
                  });
                  setShowPassModal(true);
                }}
              >
                <i className="bi bi-pencil-fill"></i>
              </span>
              &nbsp;&nbsp;
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => deleteUserHandle(tableMeta.rowData[0])}
              >
                <i className="bi bi-trash-fill"></i>
              </span>
            </>
          );
        },
      },
    },
  ];

  const getAllUsers = useCallback(async () => {
    const res = await Api.getAllUsers();
    if (res.status === 200) {
      setData(res.data.data);
      setIsLoading(false);
    }
  }, [setData]);

  useLayoutEffect(() => {
    getAllUsers();
  }, []);
  const deleteUserHandle = async (id) => {
    console.log(id);
    const res = await Api.deleteUser(id);
    if (res.status === 200) {
      getAllUsers();
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div
              className="c_card"
              style={{ height: "100%", overflowY: "scroll" }}
            >
              <CacheProvider value={muiCache}>
                <ThemeProvider theme={createTheme()}>
                  <MUIDataTable
                    title="Users"
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              </CacheProvider>
            </div>
          </Panel>
          {showModal && (
            <CustomModal
              title="Change User Status"
              onHide={() => setShowModal(false)}
            >
              <EditStatus
                id={editUser.id}
                status={editUser.status}
                onHide={() => setShowModal(false)}
                getAllUsers={getAllUsers}
              />
            </CustomModal>
          )}
          {showRoleModal && (
            <CustomModal
              title="Change Role"
              onHide={() => setShowRoleModal(false)}
            >
              <EditRole
                id={editRole.id}
                type={editRole.type}
                onHide={() => setShowRoleModal(false)}
                getAllUsers={getAllUsers}
              />
            </CustomModal>
          )}
          {showPassModal && (
            <CustomModal
              title="Change Password"
              onHide={() => setShowPassModal(false)}
            >
              <EditPass
                id={editPass.id}
                onHide={() => setShowPassModal(false)}
                getAllUsers={getAllUsers}
              />
            </CustomModal>
          )}
        </div>
      )}
    </>
  );
};

const Active = ({ data, index, text }) => {
  return (
    <span
      style={{
        display: "block",
        padding: "2px 6px",
        width: "fit-content",
        textTransform: "capitalize",
        fontWeight: 600,
        borderRadius: 6,
        fontSize: 13,
        background: data.rowData[index] === text ? "#2563eb33" : "#ff537433",
        color: data.rowData[index] === text ? "#2563EB" : "#ff5374",
      }}
    >
      {`${data.rowData[index]}`}
    </span>
  );
};

const EditStatus = memo(({ id, status, onHide, getAllUsers }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({
    id,
    status,
  });

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    const res = await Api.updateUserStatus({
      id: data.id,
      status: data.status,
    });
    if (res.status === 200) {
      getAllUsers();
      onHide();
    }
    setIsDisable(false);
  };

  useEffect(() => {
    setData({ id, status });
  }, []);

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <select
          required
          name="status"
          value={data.status}
          onChange={(e) =>
            setData((prev) => ({ ...prev, status: e.target.value }))
          }
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
      <Button disabled={isDisable} type="submit" width="100%">
        {!isDisable ? (
          "Update"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});
const EditPass = memo(({ id, type, onHide, getAllUsers }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({
    id,
    newPassword: "",
    confirmPassword: "",
  });
  const inputHandle = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const submitHandle1 = async (e) => {
    e.preventDefault();
    if (data.newPassword !== data.confirmPassword)
      return Notifications("warning", "Confirm Password doesn't match");

    setIsDisable(true);
    const res = await Api.updatePasswordByAdmin(data);
    if (res.status === 200) {
      Notifications("success", res.data.message);
      setData({
        id: "",
        newPassword: "",
        confirmPassword: "",
      });
      onHide();
    }
    setIsDisable(false);
  };

  useEffect(() => {
    setData({ id, type });
  }, []);
  return (
    <form onSubmit={submitHandle1}>
      <div className="c_input_wrapper">
        <input
          type="password"
          name="newPassword"
          value={data.newPassword}
          onChange={inputHandle}
          placeholder="New Password"
          required
        />
      </div>
      <div className="c_input_wrapper">
        <input
          type="password"
          name="confirmPassword"
          value={data.confirmPassword}
          onChange={inputHandle}
          placeholder="Confirm Password"
          required
        />
      </div>
      <Button disabled={isDisable} type="submit" width="100%">
        {!isDisable ? (
          "Update"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});
const EditRole = memo(({ id, type, onHide, getAllUsers }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({
    id,
    type,
  });

  const submitHandle1 = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    const res = await Api.updateRole({
      id: data.id,
      type: data.type,
    });
    if (res.status === 200) {
      getAllUsers();
      onHide();
    }
    setIsDisable(false);
  };

  useEffect(() => {
    setData({ id, type });
  }, []);
  return (
    <form onSubmit={submitHandle1}>
      <div className="c_input_wrapper">
        <select
          required
          name="type"
          value={data.type}
          onChange={(e) =>
            setData((prev) => ({ ...prev, type: e.target.value }))
          }
        >
          {/* <option value=""></option> */}
          <option value="admin">Admin</option>
          <option value="user">User</option>
        </select>
      </div>
      <Button disabled={isDisable} type="submit" width="100%">
        {!isDisable ? (
          "Update"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});

export default Users;
