import Login from "../pages/Login";
import Cards from "../pages/Cards";
import EditCard from "../pages/EditCard";
import PreviewCard from "../pages/PreviewCard";
import Profile from "../pages/Profile";
import Reviews from "../pages/Reviews";
import Languages from "../pages/Languages";
import EditLanguage from "../pages/EditLanguage";
import MainIcons from "../pages/MainIcons";
import FloatingIcons from "../pages/FloatingIcon";
import Home from "../pages/home";
import Users from "../pages/Users";
import Earnings from "../pages/Earnings";
import Testimonial from "../pages/Testimonial";
import Content from "../pages/Content";
import Alert from "../pages/Alert";

export const PROTECTED_ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/cards",
    component: Cards,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/preview/:title",
    component: PreviewCard,
  },
  {
    path: "/card/:title",
    component: EditCard,
  },
  {
    path: "/reviews",
    component: Reviews,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/languages",
    component: Languages,
  },
  {
    path: "/language/:name",
    component: EditLanguage,
  },
  {
    path: "/icons/main",
    component: MainIcons,
  },
  {
    path: "/icons/floating",
    component: FloatingIcons,
  },
  {
    path: "/earnings",
    component: Earnings,
  },
  {
    path: "/testimonials",
    component: Testimonial,
  },
  {
    path: "/images",
    component: Content,
  },
  {
    path: "/alerts",
    component: Alert,
  },
];

export const PROTECTED_AUTH = [
  {
    path: "/login",
    component: Login,
  },
];
