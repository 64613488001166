import { useState, memo, useCallback, useLayoutEffect, useEffect } from "react"
import { Link } from "react-router-dom"
import ReactSwitch from "react-switch"
import Panel from "../layout/Panel/Panel"
import Api from "../api/api"
import Loader from "../components/loader/loader"

const Reviews = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [reviews, setReviews] = useState([])

  const getAllReviews = useCallback(async () => {
    const res = await Api.getAllUserReviews()
    if (res.status === 200) {
      setReviews(res.data.data)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [])

  const deleteReview = async (id) => {
    await Api.deleteReview(id)
    getAllReviews()
  }

  useLayoutEffect(() => {
    getAllReviews()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div
              className="c_card"
              style={{ height: "100%" }}
            >
              <div style={{ overflowX: "scroll" }}>
                <table className="c_table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th style={{ width: "40%" }}>Review</th>
                      <th>Rating</th>
                      <th>Approved</th>
                      <th style={{ width: 80 }}>Action</th>
                      <th style={{ width: 110 }}>Created at</th>
                      <th style={{ width: 114 }}>Updated at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviews &&
                      reviews.map((item) => (
                        <Tr
                          id={item.id}
                          url_title={item.card.URL_title}
                          title={item.card?.title}
                          description={item.description}
                          created={new Date(item.createdAt).toLocaleString()}
                          updated={new Date(item.updatedAt).toLocaleString()}
                          rating={item.rating}
                          isApproved={item.isApproved}
                          deleteReview={deleteReview}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Panel>
        </div>
      )}
    </>
  )
}

const Tr = memo(
  ({
    id,
    url_title,
    created,
    updated,
    title,
    description,
    rating,
    isApproved,
    deleteReview,
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const approveHandle = useCallback(async () => {
      setIsOpen((prev) => !prev)
      await Api.editReviewStatus(id)
    }, [setIsOpen])

    useEffect(() => {
      setIsOpen(isApproved)
    }, [isApproved])

    return (
      <tr key={id}>
        <td>{title}</td>
        <td>{description}</td>
        <td>{rating}</td>
        <td>
          <ReactSwitch
            checked={isOpen}
            onChange={approveHandle}
            width={42}
            height={20}
            onColor="#2563EB"
            handleDiameter={16}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        </td>
        <td>
          {/* <Link to={`/card/${url_title}`}> */}
          <i className="bi bi-eye-fill"></i>
          {/* </Link> */}
          &nbsp;&nbsp;
          <span onClick={() => deleteReview(id)}>
            <i className="bi bi-trash-fill"></i>
          </span>
        </td>
        <td>{created}</td>
        <td>{updated}</td>
      </tr>
    )
  },
)
export default Reviews
