export const JWT_STORAGE_NAME = "wazcardAdminToken"
export const LOCAL_STORAGE_NAME = "wazcardAdmin"
export const LANG_NAME = "wazcardLang"

export const OVERRIDE = {
  display: "block",
  margin: "0 auto",
  border: "3px solid",
  borderColor: "#FFFFFF",
}
