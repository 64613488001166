import "./Header.css"
import { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggleSidebar } from "../../store/slices/sidebarSlice"
import Usermenu from "../usermenu/Usermenu"

const Header = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const showUserMenuRef = useRef()
  const [showUserMenu, setShowUserMenu] = useState(false)

  return (
    <header className="panel_header c_card">
      <div className="header_cont">
        <div className="header_left">
          <div className="hamburger">
            <span onClick={() => dispatch(toggleSidebar())}>
              <i className="bi bi-list"></i>
            </span>
          </div>
        </div>
        <div className="header_center"></div>
        <div
          className="header_right"
          onClick={() => setShowUserMenu(true)}
        >
          <div className="avatar">
            <img
              src={user?.avatar}
              alt=""
            />
            {showUserMenu && (
              <Usermenu
                avatar={user.avatar}
                name={user.name}
                email={user.email}
                showUserMenuRef={showUserMenuRef}
                setShowUserMenu={setShowUserMenu}
              />
            )}
          </div>
          <span>{user?.name}</span>
        </div>
      </div>
    </header>
  )
}

export default Header
