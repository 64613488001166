import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ReviewCard from "../../review/review"
import Button from "../../../common/Button"
import DesignCard from "../../../layout/designCard/designCard"
import AddReview from "../../review/add"
import { designCard } from "../../../store/slices/cardSlice"
import Api from "../../../api/api"

const Reviews = ({ toggle, setToggle }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.card)
  const [showModal, setShowModal] = useState(false)

  const colorInputHandle = (e) => {
    const { name, value } = e.target
    dispatch(designCard({ ...state, [name]: value }))
  }

  const submitColorHandle = async (e) => {
    const { name, value } = e.target
    await Api.updateReviewColor({ id: state.id, [name]: value })
  }

  return (
    <DesignCard
      title="Reviews"
      toggle={toggle}
      onShow={() => setToggle(5, !toggle)}
    >
      <div className="design_input_wrapper">
        <label>Background Color</label>
        <input
          type="color"
          name="reviews_bg"
          value={state.reviews_bg}
          onChange={colorInputHandle}
          onBlur={submitColorHandle}
        />
      </div>

      <Button onClick={() => setShowModal(true)}>Add Reviews</Button>

      {state.reviews.length > 0 &&
        state.reviews
          .map((item) => (
            <ReviewCard
              id={item.id}
              name={item.name}
              rating={item.rating}
              description={item.description}
              createdAt={new Date(item.createdAt).toLocaleDateString()}
              showAction={true}
              logo={state.logo}
              title={state.title}
            />
          ))
          .reverse()}

      {showModal && (
        <AddReview
          isApproved={true}
          logo={state.logo}
          title={state.title}
          isBgShow={false}
          onHide={() => setShowModal(false)}
        />
      )}
    </DesignCard>
  )
}

export default Reviews
