import * as React from "react";
import { useState, memo, useCallback, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "../../common/Button";
import { Notifications } from "../../helper/notifications";
import { OVERRIDE } from "../../utils/constants";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Api from "../../api/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditAlert = memo(({ editingItem, onHide, users, getAlerts }) => {
  console.log("edititen", editingItem);
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({
    description: editingItem.description,
    url: editingItem.url,
    user_id: editingItem.user_id,
    date: editingItem.date,
    count: editingItem.count,
  });

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    const id = editingItem.id;
    console.log("ddate", id);
    const res = await Api.updateAlertData({ id, data });
    console.log("rrrrrrr", res);
    if (res.status === 200) {
      getAlerts();
      Notifications("success", res.data.message);
      onHide();
    }
    setIsDisable(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    setData((prevState) => ({
      ...prevState,
      user_id: value, // Directly set the user_id to the selected user's ID
    }));
  };

  const handleDateChange = (event) => {
    const {
      target: { value },
    } = event;
    setData((prevState) => ({
      ...prevState,
      date: value,
    }));
  };

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <div className="my-3">
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Description
          </label>
          <input
            type="text"
            name="description"
            id="description"
            value={data.description}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
        </div>
        <div className="my-3">
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Url
          </label>
          <input
            type="text"
            name="url"
            id="url"
            value={data.url}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
        </div>
        <div>
          <FormControl sx={{ m: 1 }} className="w-full !m-0">
            <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
            <Select
              labelId="demo-single-checkbox-label"
              id="demo-single-checkbox"
              value={data.user_id}
              onChange={handleUserChange}
              input={<OutlinedInput label="User" />}
              MenuProps={MenuProps}
              required
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <label
            htmlFor="date"
            className="block mb-2 mt-3 text-sm font-medium text-gray-900"
          >
            Select Dates:
          </label>
          <input
            type="date"
            id="date"
            value={data.date}
            onChange={handleDateChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
        </div>
        <div className="my-3">
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Count
          </label>
          <input
            type="number"
            name="count"
            id="count"
            value={data.count}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder=""
            required
          />
        </div>
      </div>
      <Button disabled={isDisable} type="submit" width="100%">
        {!isDisable ? (
          "Edit Alert"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});
export default EditAlert;
