import "react-phone-number-input/style.css";
import { useState, memo, useLayoutEffect, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import MUIDataTable from "mui-datatables";
import createCache from "@emotion/cache";
import PhoneInput from "react-phone-number-input";
import ClipLoader from "react-spinners/ClipLoader";
import Panel from "../layout/Panel/Panel";
import Button from "../common/Button";
import CustomModal from "../layout/modal/modal";
import Api from "../api/api";
import Loader from "../components/loader/loader";
import { OVERRIDE } from "../utils/constants";
import { lookupCountryName } from "../utils/countryNames";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

const Home = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [adminCards, setAdminCards] = useState([]);
  const [userCards, setUserCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLangModal, setShowLangModal] = useState(false);
  const [editLang, setEditLang] = useState({ id: 0, cid: 0 });
  const [showExpiryModal, setShowExpiryModal] = useState(false);
  const [editExpiry, setEditExpiry] = useState({ cid: 0, date: "" });
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [editStatus, setEditStatus] = useState({ id: 0, status: "" });
  const [languagesData, setLanguagesData] = useState([]);

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none", // toggle checkbox
    onTableChange: (action, state) => {},
  };

  const columns = [
    {
      name: "uid",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "URL_title",
      label: "URL",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href={`https://wazcard.com/preview/${tableMeta.rowData[2]}`}
              target="_blank"
            >
              {tableMeta.rowData[2]}
            </a>
          );
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "likes",
      label: "Likes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "views",
      label: "Views",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "deviceName",
      label: "Device",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "language",
      label: "Language",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p>
              <span>{tableMeta.rowData[8].lang_name}</span>&nbsp;&nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditLang({
                    cid: tableMeta.rowData[1],
                    id: tableMeta.rowData[8].id,
                  });
                  setShowLangModal(true);
                }}
              >
                <i className="bi bi-pencil-fill"></i>
              </span>
            </p>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Active data={tableMeta} index={9} text="active" />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditStatus({
                    id: tableMeta.rowData[1],
                    status: tableMeta.rowData[9],
                  });
                  setShowStatusModal(true);
                }}
              >
                <i className="bi bi-pencil-fill"></i>
              </span>
            </p>
          );
        },
      },
    },
    // {
    //   name: "planStatus",
    //   label: "Plan Status",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <p style={{ display: "flex", alignItems: "center", gap: 10 }}>
    //           {/* <Active
    //             data={tableMeta}
    //             index={9}
    //             text="active"
    //           /> */}
    //           <span
    //             style={{ cursor: "pointer" }}
    //             onClick={() => {
    //               setEditExpiry({
    //                 cid: tableMeta.rowData[1],
    //                 date: "2020/3/3",
    //               })
    //               setShowExpiryModal(true)
    //             }}
    //           >
    //             <i className="bi bi-pencil-fill"></i>
    //           </span>
    //         </p>
    //       )
    //     },
    //   },
    // },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => editCardHandle(tableMeta)}
              >
                <i className="bi bi-pencil-fill"></i>
              </span>
              &nbsp;&nbsp;
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => deleteCardHandle(tableMeta)}
              >
                <i className="bi bi-trash-fill"></i>
              </span>
            </>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Created at",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>{new Date(tableMeta.rowData[11]).toLocaleString()}</span>
          );
        },
      },
    },
    {
      name: "updatedAt",
      label: "Updated at",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>{new Date(tableMeta.rowData[12]).toLocaleString()}</span>
          );
        },
      },
    },
  ];

  const editCardHandle = async (meta) => {
    console.log(`/card/${meta.rowData[2]}`);
    history.push(`/card/${meta.rowData[2]}`);
  };

  const deleteCardHandle = async (meta) => {
    const res = await Api.deleteCard(meta.rowData[1]);
    if (res.status === 200) {
      getUsersCard();
      getAdminCard();
    }
  };

  const getAdminCard = useCallback(async () => {
    const res = await Api.getAdminCards();
    if (res.status === 200) {
      setAdminCards(res.data.data);
    }
    setIsLoading(false);
  }, [setAdminCards]);

  const getUsersCard = useCallback(async () => {
    const res = await Api.getUsersCard();
    if (res.status === 200) {
      setUserCards(res.data.data);
    }
  }, [setUserCards]);

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages();
    if (res.status === 200) {
      setLanguagesData(res.data.data);
    }
  }, [setLanguagesData]);

  useLayoutEffect(() => {
    getUsersCard();
    getAdminCard();
    getAllLanguages();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div
              className="c_card"
              style={{ height: "100%", overflowY: "scroll" }}
            >
              <Button
                onClick={() => setShowModal(true)}
                style={{
                  marginLeft: "auto",
                  marginBottom: 14,
                }}
              >
                Create Card
              </Button>
              <CacheProvider value={muiCache}>
                <ThemeProvider theme={createTheme()}>
                  <MUIDataTable
                    title="Admin's Cards"
                    data={adminCards}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              </CacheProvider>
              <br />
              <CacheProvider value={muiCache}>
                <ThemeProvider theme={createTheme()}>
                  <MUIDataTable
                    title="User's Cards"
                    data={userCards}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              </CacheProvider>
            </div>

            {showModal && (
              <CustomModal
                title="Create New Card"
                onHide={() => setShowModal(false)}
              >
                <AddForm languagesData={languagesData} />
              </CustomModal>
            )}

            {showLangModal && (
              <CustomModal
                title="Edit Card Language"
                onHide={() => setShowLangModal(false)}
              >
                <EditLanguage
                  languages={languagesData}
                  id={editLang.id}
                  cid={editLang.cid}
                />
              </CustomModal>
            )}

            {showExpiryModal && (
              <CustomModal
                title="Edit Card Expiry"
                onHide={() => setShowExpiryModal(false)}
              >
                <EditExpiry id={editExpiry.cid} date={editExpiry.date} />
              </CustomModal>
            )}

            {showStatusModal && (
              <CustomModal
                title="Change Card Status"
                onHide={() => setShowStatusModal(false)}
              >
                <EditStatus
                  id={editStatus.id}
                  status={editStatus.status}
                  getAdminCard={getAdminCard}
                  getUsersCard={getUsersCard}
                  onHide={() => setShowStatusModal(false)}
                />
              </CustomModal>
            )}
          </Panel>
        </div>
      )}
    </>
  );
};

const Active = ({ data, index, text }) => {
  return (
    <span
      style={{
        display: "block",
        padding: "2px 6px",
        width: "fit-content",
        textTransform: "capitalize",
        fontWeight: 600,
        borderRadius: 6,
        fontSize: 13,
        background: data.rowData[index] === text ? "#2563eb33" : "#ff537433",
        color: data.rowData[index] === text ? "#2563EB" : "#ff5374",
      }}
    >
      {`${data.rowData[index]}`}
    </span>
  );
};

const EditStatus = memo(
  ({ id, status, getAdminCard, getUsersCard, onHide }) => {
    const [isDisable, setIsDisable] = useState(false);
    const [data, setData] = useState({
      id,
      status,
    });

    const submitHandle = async (e) => {
      e.preventDefault();
      setIsDisable(true);
      const res = await Api.updateCardStatus({
        id: data.id,
        status: data.status,
      });
      if (res.status === 200) {
        getAdminCard();
        getUsersCard();
      }
      setIsDisable(false);
      onHide();
    };

    useEffect(() => {
      setData({ id, status });
    }, []);

    return (
      <form onSubmit={submitHandle}>
        <div className="c_input_wrapper">
          <select
            required
            name="status"
            value={data.status}
            onChange={(e) =>
              setData((prev) => ({ ...prev, status: e.target.value }))
            }
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        <Button disabled={isDisable} type="submit" width="100%">
          {!isDisable ? (
            "Update"
          ) : (
            <ClipLoader
              color="#FFFFFF"
              loading={true}
              cssOverride={OVERRIDE}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </Button>
      </form>
    );
  }
);

const AddForm = memo(({ languagesData }) => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState({
    title: "",
    lang_id: "",
    number: "",
  });

  const submitHandle = async (e) => {
    e.preventDefault();
    const parsedPhoneNumber = parsePhoneNumber(data.number);
    if (!parsedPhoneNumber.isValid()) return alert("Invalid number");
    const countryCode = parsedPhoneNumber.country.toLowerCase();

    let title = data.title.trim().replaceAll(" ", "-");
    title = `${title}.${countryCode}`.toLowerCase();

    setIsDisabled(true);
    const res = await Api.createCard({
      title: data.title.trim(),
      URL_title: `${title}`,
      lang_id: data.lang_id,
      number: data.number,
      deviceName: getDeviceName(),
      country: lookupCountryName(countryCode),
    });
    if (res.status === 201) {
      history.push(`/card/${title}`);
      setIsDisabled(false);
    } else if (res.status === 409) {
      alert("Use unique title");
      setIsDisabled(false);
    } else {
      setIsDisabled(false);
    }
  };

  const getDeviceName = () => {
    const platform = navigator.platform?.toLowerCase();
    let deviceName = "";

    if (/win/.test(platform)) {
      deviceName = "Windows";
    } else if (/mac/.test(platform)) {
      deviceName = "Mac";
    } else if (/linux/.test(platform)) {
      deviceName = "Linux";
    } else if (/iphone|ipad|ipod/.test(platform)) {
      deviceName = "iOS";
    } else if (/arm|aarch/.test(platform)) {
      deviceName = "ARM-based";
    } else if (/android/.test(platform)) {
      deviceName = "Android";
    } else {
      deviceName = "";
    }

    return deviceName;
  };

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={data.title}
          onChange={(e) =>
            setData((prev) => ({ ...prev, title: e.target.value }))
          }
          placeholder="Business Title"
          required
        />
      </div>
      <div className="c_input_wrapper">
        <select
          required
          name="language"
          value={data.lang_id}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lang_id: e.target.value }))
          }
        >
          <option value="">-- Select Language --</option>
          {languagesData.map((item) => (
            <option value={item.id}>{item.lang_name}</option>
          ))}
        </select>
      </div>
      <div className="c_input_wrapper">
        <PhoneInput
          required
          value={data.number}
          onChange={(value) => setData((prev) => ({ ...prev, number: value }))}
          international
          placeholder="Number"
        />
      </div>
      <Button disabled={isDisabled} width="100%">
        {!isDisabled ? (
          "Create"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});

const EditLanguage = memo(({ languages, id, cid }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState({
    id,
    cid,
  });

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const res = await Api.updateCardLanguage(data);
    if (res.status === 200) {
      window.location.reload();
    }
  };

  useEffect(() => {
    setData({ id, cid });
  }, []);

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <select
          required
          name="language"
          value={data.id}
          onChange={(e) => setData((prev) => ({ ...prev, id: e.target.value }))}
        >
          <option value="">-- Select Language --</option>
          {languages.map((item) => (
            <option value={item.id}>{item.lang_name}</option>
          ))}
        </select>
      </div>
      <Button disabled={isDisabled} width="100%">
        {!isDisabled ? (
          "Edit"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});

const EditExpiry = memo(({ cid, date }) => {
  const [data, setData] = useState({
    date,
    cid,
  });

  const submitHandle = async (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setData({ cid, date });
  }, []);

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="date"
          required
          name="language"
          value={data.id}
          onChange={(e) =>
            setData((prev) => ({ ...prev, date: e.target.value }))
          }
        />
      </div>
      <Button width="100%">Edit</Button>
    </form>
  );
});

export default Home;
