export const languages = [
  { label: "About Us", name: "aboutUs" },
  { label: "Packages", name: "packages" },
  { label: "Edit Card", name: "editCard" },
  { label: "Edit", name: "edit" },
  { label: "Public", name: "public" },
  { label: "Weekly Visitor", name: "weVisitor" },
  { label: "Monthly Visitor", name: "moVisitor" },
  { label: "Created Cards", name: "createdCard" },
  { label: "Newly Created Cards", name: "newCreatedCard" },
  { label: "How It Works", name: "howItWorks" },
  { label: "Get A Quote", name: "getAQuote" },
  {
    label: "Our Captivating Template Collection",
    name: "ourCaptivatingTemplateCollection",
  },
  { label: "Customer Testimonials", name: "customerTestimonials" },
  { label: "Basic", name: "basic" },
  { label: "Standard", name: "standard" },
  { label: "Premium", name: "premium" },
  { label: "Great For Starters", name: "greatForStarters" },
  { label: "For Planned Projects", name: "forPlannedProjects" },
  { label: "Customizable Design Options", name: "customizableDesignOptions" },
  { label: "Pricing Plans", name: "pricingPlans" },
  { label: "Social Media Integration", name: "socialMediaIntegration" },
  { label: "Mobile-Friendly Layouts", name: "mobileFriendlyLayouts" },
  { label: "Easy Sharing Capabilities", name: "easySharingCapabilities" },
  {
    label: "Analytics for Tracking Growth",
    name: "analyticsForTrackingGrowth",
  },
  { label: "Get Now", name: "getNow" },
  { label: "Hero Title", name: "heroTitle" },
  { label: "Hero Description", name: "heroDescription" },
  { label: "Working Step 1 Title", name: "workingStep1Title" },
  { label: "Working Step 1 Description", name: "workingStep1Description" },
  { label: "Working Step 2 Title", name: "workingStep2Title" },
  { label: "Working Step 2 Description", name: "workingStep2Description" },
  { label: "Working Step 3 Title", name: "workingStep3Title" },
  { label: "Working Step 3 Description", name: "workingStep3Description" },
  { label: "Working Step 4 Title", name: "workingStep4Title" },
  { label: "Working Step 4 Description", name: "workingStep4Description" },
  { label: "Business Title", name: "bussinessTitle" },
  { label: "Business Description", name: "bussinessDescription" },
  { label: "Business 1 Label", name: "business1Label" },
  { label: "Business 1 Description", name: "business1Description" },
  { label: "Business 2 Label", name: "business2Label" },
  { label: "Business 2 Description", name: "business2Description" },
  { label: "Business 3 Label", name: "business3Label" },
  { label: "Business 3 Description", name: "business3Description" },
  { label: "Need The Same Thing?", name: "needTheSameThing" },
  { label: "Click To Get", name: "clickToGet" },
  { label: "Footer Text", name: "footerText" },
  { label: "Copy Right", name: "copyRight" },
  { label: "All Rights Reserved", name: "allRightsReserved" },
  { label: "Terms And Services", name: "termAndServices" },
  { label: "Privacy Policy", name: "privacyPolicy" },
  { label: "Cookie Policy", name: "cookiePolicy" },
  { label: "Contact Us", name: "contactUs" },
  { label: "Legal", name: "legal" },
  { label: "Links", name: "links" },
  { label: "Email", name: "email" },
  { label: "Phone", name: "phone" },
  { label: "Payment Type 1 Name", name: "paymentType1Name" },
  { label: "Payment Type 1 Desc", name: "paymentType1Desc" },
  { label: "Payment Type 1 Price", name: "paymentType1Price" },
  { label: "Payment Type 2 Name", name: "paymentType2Name" },
  { label: "Payment Type 2 Desc", name: "paymentType2Desc" },
  { label: "Payment Type 2 Price", name: "paymentType2Price" },
  { label: "Payment Type 3 Name", name: "paymentType3Name" },
  { label: "Payment Type 3 Desc", name: "paymentType3Desc" },
  { label: "Payment Type 3 Price", name: "paymentType3Price" },
  { label: "Payment Link 1", name: "paymentLink1" },
  { label: "Payment Link 2", name: "paymentLink2" },
  { label: "Payment Link 3", name: "paymentLink3" },
  { label: "Payment Link 4", name: "paymentLink4" },
  { label: "Payment Link 5", name: "paymentLink5" },
];
