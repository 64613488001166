import { memo, useMemo } from "react"
import Chart from "react-apexcharts"

const PieChart = ({ activeCards, inActiveCards }) => {
  const options = useMemo(() => {
    return {
      chart: {
        type: "pie",
        width: "100%",
      },
      labels: ["Active", "Inactive"],
      colors: ["#2563EB", "#ff5374"],
      series: [activeCards || 0, inActiveCards || 0],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 575,
          options: {},
        },
      ],
    }
  }, [activeCards, inActiveCards])

  return (
    <div className="c_card">
      <p style={{ fontWeight: 600 }}>Cards Status</p>
      <Chart
        options={options}
        series={options.series}
        type="pie"
        height={350}
      />
    </div>
  )
}

export default memo(PieChart)
