import styled from "styled-components";

const Button = styled.button`
  border-radius: var(--border_radius);
  background: ${({ bg }) => bg || "var(--primary)"};
  padding: 8px 14px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  border: 0;
  display: block;
  width: ${({ width }) => width || "auto"};
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export default Button;
