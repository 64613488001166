export const LINKS = [
  {
    to: "/",
    title: "Dashboard",
  },
  {
    to: "/cards",
    title: "Digital Cards",
  },
  {
    to: "/users",
    title: "Users",
  },
  {
    to: "/languages",
    title: "Languages",
  },
  {
    to: "/icons/main",
    title: "Main Icons",
  },
  {
    to: "/icons/floating",
    title: "Floating Icons",
  },
  {
    to: "/reviews",
    title: "Reviews",
  },
  {
    to: "/profile",
    title: "Profile Settings",
  },
  {
    to: "/testimonials",
    title: "Testimonials",
  },
  {
    to: "/images",
    title: "Images",
  },
  {
    to: "/alerts",
    title: "Alerts",
  },
];
