import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ReactStars from "react-rating-stars-component"
import styled from "styled-components"
import ClipLoader from "react-spinners/ClipLoader"
import Button from "../../common/Button"
import CustomModal from "../../layout/modal/modal"
import Api from "../../api/api"
import { designCard } from "../../store/slices/cardSlice"
import { OVERRIDE } from "../../utils/constants"

const AddReview = ({
  isApproved,
  title,
  bg,
  isBgShow,
  logo,
  onHide,
  color,
}) => {
  const dispatch = useDispatch()
  const { title: title_ } = useParams()
  const { id } = useSelector((state) => state.card)
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    name: "",
    rating: "",
    isApproved: true,
    description: "",
    createdAt: new Date().toLocaleDateString(),
  })

  const dataSubmitHandle = async () => {
    if (!data.name || !data.rating || !data.description) return
    setIsDisable(true)
    // dispatch(addReview({ ...data, id, isApproved }))
    const res = await Api.addReview({ ...data, cid: id, isApproved })
    if (res.status === 201) {
      fetchCardData()
      setIsDisable(false)
      onHide()
    } else {
      setIsDisable(false)
    }
  }

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCardPublic(title_)
    if (res.status === 200) {
      dispatch(designCard(res.data.data))
      onHide()
    }
  }, [])

  return (
    <CustomModal
      title="Add Reviews"
      color={color}
      bg={bg}
      isBgShow={isBgShow}
      onHide={onHide}
    >
      <LogoWrapper>
        <img
          src={logo}
          alt={title}
        />
      </LogoWrapper>
      <P>
        Rate your experience with <strong>{title}</strong>
      </P>
      <ReactStars
        count={5}
        value={parseInt(data.rating)}
        size={28}
        activeColor="#F3B455"
        onChange={(value) => setData((prev) => ({ ...prev, rating: value }))}
      />
      <div style={{ marginBottom: 8 }}>
        <Input
          color={color}
          type="text"
          value={data.name}
          onChange={(e) =>
            setData((prev) => ({ ...prev, name: e.target.value }))
          }
          placeholder="Name"
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <TextArea
          color={color}
          type="text"
          value={data.description}
          onChange={(e) =>
            setData((prev) => ({ ...prev, description: e.target.value }))
          }
          placeholder="Your Experience"
        />
      </div>
      <Button
        disabled={isDisable}
        width="100%"
        onClick={dataSubmitHandle}
      >
        {!isDisable ? (
          "Add Review"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </CustomModal>
  )
}

const Input = styled.input`
  background-color: transparent;
  color: ${({ color }) => color || "#000000"};
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  font-size: 15px;
  padding: 10px;
  width: 100%;

  &::placeholder {
    color: #c0c0c0;
  }
`

const TextArea = styled.textarea`
  background-color: transparent;
  color: ${({ color }) => color || "#000000"};
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  font-size: 15px;
  padding: 10px;
  width: 100%;

  &::placeholder {
    color: #c0c0c0;
  }
`
const P = styled.p`
  text-align: center;
  margin-top: 8px;
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }
`

export default AddReview
