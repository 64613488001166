import { useCallback, useLayoutEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { designCard } from "../store/slices/cardSlice"
import Api from "../api/api"
import Panel from "../layout/Panel/Panel"
import CardBuilder from "../components/builder/builder"
import Loader from "../components/loader/loader"

const EditCard = () => {
  const { title } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCard(title)
    if (res.status === 200) {
      if (res.data.data.language.rtl) {
        document.querySelector("body").classList.add("preview_rtl")
      }
      dispatch(designCard(res.data.data))
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (res.status === 404) {
      window.location.href = "/"
    } else {
      setIsLoading(false)
    }
  }, [])

  useLayoutEffect(() => {
    fetchCardData()

    return () => {
      document.querySelector("body").classList.remove("preview_rtl")
    }
  }, [])

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Panel>
            <CardBuilder />
          </Panel>
        </>
      )}
    </div>
  )
}

export default EditCard
