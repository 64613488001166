import { memo, useState, useMemo } from "react"
import AddReview from "../../review/add"
import Carousel from "react-multi-carousel"
import ReviewCard from "../../review/review"

const Reviews = ({ bg, color, logo, title, reviews_bg, reviews, language }) => {
  const [addReview, setAddReview] = useState(false)
  const approvedReviews = useMemo(
    () =>
      reviews.length > 0 ? reviews.filter((review) => review.isApproved) : 0,
    [reviews],
  )

  const avgRating = useMemo(
    () =>
      approvedReviews.length > 0
        ? (
            approvedReviews.reduce((acc, item) => acc + item.rating, 0) /
            approvedReviews.length
          ).toFixed(1)
        : 0,
    [approvedReviews],
  )

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 786 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <div className="card_reviews">
      <div className="card_title">
        {language.reviews} <span style={{ color: "#F3B455" }}>{avgRating}</span>{" "}
        <span style={{ fontWeight: 500 }}>({approvedReviews.length})</span>
      </div>

      <Carousel
        responsive={responsive}
        arrows={false}
        showDots={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {approvedReviews.length > 0 &&
          approvedReviews
            .map(
              (item) =>
                item.isApproved && (
                  <ReviewCard
                    id={item.id}
                    name={item.name}
                    rating={item.rating}
                    description={item.description}
                    createdAt={new Date(item.createdAt).toLocaleDateString()}
                    showAction={false}
                    bg={reviews_bg}
                    color={color}
                    mr={10}
                  />
                ),
            )
            .reverse()}
      </Carousel>

      <button
        className="card_button"
        onClick={() => setAddReview(true)}
        style={{ color: "#F3B455", marginTop: 10 }}
      >
        + {language.addReviews}
      </button>

      {addReview && (
        <AddReview
          isApproved={false}
          logo={logo}
          title={title}
          bg={bg}
          isBgShow={true}
          color={color}
          onHide={() => setAddReview(false)}
        />
      )}
    </div>
  )
}

export default memo(Reviews)
