import { useSelector } from "react-redux"
import "./Sidebar.css"
import { Link } from "react-router-dom"
import { LINKS } from "../../utils/links"
import logo from "../../assets/Mask group.png"

const Sidebar = () => {
  const toggleSidebar = useSelector((state) => state.sidebar)
  const activeLink = (id) => {
    if (id === window.location.pathname) return "active"
  }
  return (
    <div
      className="sidebar"
      style={{ left: toggleSidebar ? "1rem" : "-280px" }}
    >
      <div className="c_card">
        <div className="sidebar_header">
          {/* <div
            className="logo"
            style={{ margin: "0 auto 10px auto" }}
          ></div> */}
          <div className="flex items-center justify-center mt-2 mb-5">
            <img
              src={logo}
              className="h-8 mr-3"
              alt="Logo"
            />
            <span className="self-center text-sm text-blue-500">
              Digital Idea
            </span>
          </div>
          <div className="sidebar_cont">
            {LINKS.map(({ to, title }, index) => (
              <Link
                to={to}
                key={index + 1}
                className={`link ${activeLink(to)}`}
              >
                {title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
