import { memo, useMemo } from "react"
import Chart from "react-apexcharts"

const AreaChart = ({ data }) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const options = useMemo(() => {
    return {
      series: [
        {
          name: "Cards",
          color: "#2563EB",
          data: data && data.length > 0 ? data.map((item) => item.cards) : [],
        },
      ],
      xaxis: {
        categories:
          data && data?.length > 0
            ? data?.map(
                (item) =>
                  `${item.date.slice(8)}' ${
                    monthNames[parseInt(item.date.slice(5, 7) - 1)]
                  }`,
              )
            : [],
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        followCursor: true,
      },
      fill: {
        opacity: 1,
      },
      responsive: [
        {
          breakpoint: 575,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                show: false,
              },
            },
          },
        },
      ],
    }
  }, [data])

  return (
    <div className="c_card">
      <p style={{ fontWeight: 600 }}>Cards Engagement</p>
      {data?.length > 0 && (
        <Chart
          options={options}
          series={options.series}
          type="area"
          height={350}
        />
      )}
    </div>
  )
}

export default memo(AreaChart)
