import { useState, memo, useLayoutEffect, useCallback, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import ReactSwitch from "react-switch"
import ClipLoader from "react-spinners/ClipLoader"
import Panel from "../layout/Panel/Panel"
import Loader from "../components/loader/loader"
import Button from "../common/Button"
import CustomModal from "../layout/modal/modal"
import Api from "../api/api"
import { OVERRIDE } from "../utils/constants"
import { toast } from "react-toastify"

const Languages = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [data, setData] = useState([])
  const [editData, setEditData] = useState({
    id: null,
    lang_name: "",
    code: "",
  })

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages()
    if (res.status === 200) {
      setData(res.data.data)
      setIsLoading(false)
    }
  }, [setData])

  useLayoutEffect(() => {
    getAllLanguages()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div
              className="c_card"
              style={{ height: "100%", overflowX: "scroll" }}
            >
              <div className="flex justify-end items-end ">
                <Button
                  onClick={() => setShowModal(true)}
                  style={{
                    marginBottom: 14,
                  }}
                >
                  Add Language
                </Button>
              </div>
              <table className="c_table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>ID</th>
                    <th>Language</th>
                    <th>Code</th>
                    <th>RTL</th>
                    <th style={{ width: 80 }}>Edit</th>
                    <th style={{ width: 110 }}>Created at</th>
                    <th style={{ width: 114 }}>Updated at</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0
                    ? data.map((item, index) => (
                        <Tr
                          index={index + 1}
                          id={item.id}
                          lang_name={item.lang_name}
                          lang_code={item.lang_code}
                          rtl={item.rtl}
                          created={new Date(item.createdAt).toLocaleString()}
                          updated={new Date(item.updatedAt).toLocaleString()}
                          setEditData={setEditData}
                          setShowModal2={setShowModal2}
                        />
                      ))
                    : "No data available"}
                </tbody>
              </table>
            </div>
          </Panel>
          {showModal && (
            <CustomModal
              title="Add New Language"
              onHide={() => setShowModal(false)}
            >
              <AddLanguage
                onHide={() => setShowModal(false)}
                getAllLanguages={getAllLanguages}
              />
            </CustomModal>
          )}

          {showModal2 && (
            <CustomModal
              title="Add New Language"
              onHide={() => setShowModal2(false)}
            >
              <EditLanguage
                data={editData}
                onHide={() => setShowModal2(false)}
                getAllLanguages={getAllLanguages}
              />
            </CustomModal>
          )}
        </div>
      )}
    </>
  )
}

const Tr = memo(
  ({
    index,
    id,
    lang_name,
    lang_code,
    rtl,
    created,
    updated,
    setEditData,
    setShowModal2,
  }) => {
    const [isRTL, setIsRTL] = useState(false)

    const rtlHandle = useCallback(async () => {
      setIsRTL((prev) => !prev)
      await Api.updateLanguageRTL({ id, rtl: isRTL })
    }, [setIsRTL])

    useEffect(() => {
      setIsRTL(rtl)
    }, [rtl])

    return (
      <tr key={id}>
        <td>{index}</td>
        <td>{id}</td>
        <td>{lang_name}</td>
        <td>{lang_code}</td>
        <td>
          <ReactSwitch
            checked={isRTL}
            onChange={rtlHandle}
            width={42}
            height={20}
            onColor="#2563EB"
            handleDiameter={16}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        </td>
        <td>
          <Link to={`/language/${lang_code}`}>
            <i className="bi bi-eye-fill"></i>
          </Link>
          &nbsp;&nbsp;
          <span
            onClick={() => [
              setEditData({ id, lang_name, lang_code }),
              setShowModal2(true),
            ]}
          >
            <i className="bi bi-pencil-fill"></i>
          </span>
        </td>
        <td>{created}</td>
        <td>{updated}</td>
      </tr>
    )
  },
)

const AddLanguage = memo(({ onHide, getAllLanguages }) => {
  const history = useHistory()
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    lang_name: "",
    lang_code: "",
    rtl: false,
  })

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    const res = await Api.addLanguage(data)
    if (res.status === 201) {
      getAllLanguages()
      onHide()
      history.push(`/language/${data.lang_code?.toLowerCase()}`)
    }
    setIsDisable(false)
  }

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={data.lang_name}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lang_name: e.target.value }))
          }
          placeholder="Language Name"
          required
        />
      </div>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={data.lang_code}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lang_code: e.target.value }))
          }
          placeholder="Language Code"
          required
        />
      </div>
      <div className="c_input_wrapper">
        <select
          required
          name="rtl"
          value={data.rtl}
          onChange={(e) =>
            setData((prev) => ({ ...prev, rtl: e.target.value }))
          }
        >
          <option value={false}>LTR</option>
          <option value={true}>RTL</option>
        </select>
      </div>
      <Button
        disabled={isDisable}
        type="submit"
        width="100%"
      >
        {!isDisable ? (
          "Add"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  )
})

const EditLanguage = memo(({ data, onHide, getAllLanguages }) => {
  const [isDisable, setIsDisable] = useState(false)
  const [formData, setData] = useState({
    id: "",
    lang_name: "",
    lang_code: "",
  })

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    const res = await Api.updateLanguageName(formData)
    if (res.status === 200) {
      getAllLanguages()
      onHide()
    }
    setIsDisable(false)
  }

  useEffect(() => {
    setData({
      id: data.id,
      lang_name: data.lang_name,
      lang_code: data.lang_code,
    })
  }, [])

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={formData.lang_name}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lang_name: e.target.value }))
          }
          placeholder="Language Name"
          required
        />
      </div>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={formData.lang_code}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lang_code: e.target.value }))
          }
          placeholder="Language Code"
          required
        />
      </div>
      <Button
        disabled={isDisable}
        type="submit"
        width="100%"
      >
        {!isDisable ? (
          "Update"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  )
})

const AddWord = memo(({ data, onHide, getAllLanguages }) => {
  const [formData, setData] = useState({
    column: "",
  })
  const [isDisable, setIsDisable] = useState(false)

  const submitHandle = async (e) => {
    e.preventDefault()

    // space replace with underscore and lowercase
    const column = formData.column.replace(/\s+/g, "_").toLowerCase()

    setIsDisable(true)
    const res = await Api.addColumn({
      column,
    })
    if (res.status === 200) {
      // getAllLanguages();
      toast.success("Word added successfully")
      onHide()
    }
    setIsDisable(false)
  }

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={formData.column}
          onChange={(e) =>
            setData((prev) => ({ ...prev, column: e.target.value }))
          }
          placeholder="Word"
          required
        />
      </div>

      <Button
        disabled={isDisable}
        type="submit"
        width="100%"
      >
        {!isDisable ? (
          "Update"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  )
})
export default Languages
