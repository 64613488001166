import { memo, useState } from "react"
import QRCode from "react-qr-code"
import CustomModal from "../../../layout/modal/modal"

const CardLogo = ({ bg, color, title, logoURL, language }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div
      className="card_logo_wrapper"
      style={{ background: bg, position: "relative" }}
    >
      <img
        src={logoURL}
        alt="Logo"
        style={{ borderRadius: "100%" }}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <CustomModal
          rtl={language.rtl}
          title={language.qrCode}
          bg={bg}
          isBgShow={true}
          color={color}
          onHide={() => setShowModal(false)}
        >
          <div className="card_logo_wrapper">
            <img
              src={logoURL}
              alt="Logo"
              style={{ borderRadius: "100%" }}
            />
          </div>
          <p style={{ textAlign: "center", fontSize: 18, fontWeight: 600 }}>
            {language.openCameraScanCode}
          </p>
          <div
            id="qr"
            style={{
              height: "auto",
              margin: "8px auto 0 auto",
              width: 100,
            }}
          >
            <QRCode
              size={100}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={window.location.href}
              viewBox={`0 0 256 256`}
              title={title}
            />
          </div>
        </CustomModal>
      )}
    </div>
  )
}

export default memo(CardLogo)
