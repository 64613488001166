import { Link } from "react-router-dom"

const UsersTable = ({ users }) => {
  return (
    <div className="c_card">
      <Head />
      <div className="dashboard_table">
        <table className="cards_table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Avatar</th>
              <th>Name</th>
              <th>Status</th>
              <th>Is Verified</th>
            </tr>
          </thead>
          <tbody>
            {users && users.length > 0
              ? users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <img
                        src={user.avatar}
                        alt={user.name}
                        className="table_image"
                      />
                    </td>
                    <td>{user.name}</td>
                    <td>
                      <span
                        className={`status ${
                          user.status === "active" ? "active" : "expired"
                        }`}
                      >
                        {user.status}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`status ${
                          user.emailVerified ? "active" : "expired"
                        }`}
                      >
                        {`${user.emailVerified}`}
                      </span>
                    </td>
                  </tr>
                ))
              : "No Data Available"}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const Head = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p style={{ fontWeight: 600 }}>Users</p>
      <Link
        to="/users"
        style={{ color: "var(--primary)", fontSize: 14 }}
      >
        View all
      </Link>
    </div>
  )
}

export default UsersTable
