import "react-multi-carousel/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./rtl.css";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import decode from "jwt-decode";
import Cookies from "js-cookie";
import ProtectedRoute from "./protectedRoute";
import ProtectedAuth from "./protectedAuth";
import { PROTECTED_AUTH, PROTECTED_ROUTES } from "./utils/routes";
import { hideSidebar } from "./store/slices/sidebarSlice";
import { getMe } from "./store/slices/userSlice";
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "./utils/constants";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const verifyToken = () => {
    const token = Cookies.get(JWT_STORAGE_NAME);
    if (token) {
      const decode_token = decode(token);
      if (
        decode_token.exp * 1000 < new Date().getTime() ||
        Cookies.get(JWT_STORAGE_NAME) === undefined ||
        localStorage.getItem(LOCAL_STORAGE_NAME) === "undefined"
      ) {
        Cookies.remove(JWT_STORAGE_NAME);
        localStorage.removeItem(JWT_STORAGE_NAME);
        localStorage.removeItem(LOCAL_STORAGE_NAME);
        window.location.reload();
      }
    }
  };

  useLayoutEffect(() => {
    verifyToken();
  }, [location.pathname]);

  useLayoutEffect(() => {
    dispatch(hideSidebar());
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (Cookies.get(JWT_STORAGE_NAME) !== undefined) {
      dispatch(getMe());
    }
  }, []);

  return (
    <>
      {PROTECTED_ROUTES.map((route, index) => (
        <ProtectedRoute exact {...route} key={index} />
      ))}

      {PROTECTED_AUTH.map((route, index) => (
        <ProtectedAuth exact {...route} key={index} />
      ))}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme={"light"}
      />
    </>
  );
}

export default App;
